import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from "assets/constants/Constants";
import { EDIT_ICON, TRASH_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';

export class CreateSubzone extends Component {
    constructor(props) {
        super(props)
        const editData = this.props.history.location.state;
        this.state = {
            isLoading: false,
            subZone: "",
            subZoneId: null,
            buttonText: "Add Subzone",
            subZoneList: [],
            zoneList: [],
            siteId: sessionStorage.getItem("siteId"),
            siteName: sessionStorage.getItem("siteName"),
            subZoneDisabaled: false,
            subZoneUpdateButtonDisabaled : false,
            error: {}
        }
    }

    async componentDidMount() {
        const subZoneList = await this.getSubzoneList();
        const zoneList = await this.getZoneList();
        this.setState({
            isLoading: false,
            subZoneList: subZoneList.data.result,
            zoneList: zoneList.data.result
        });
    }

    getSubzoneList = async () => {
        return await axios.get(`${API_BASE_URL}/subzone/${sessionStorage.getItem("siteId")}`);
    }

    getZoneList = async () => {
        return await axios.get(`${API_BASE_URL}/zone/subzone/${this.state.siteId}`);
    }

    subZoneChange = (event) => {
        this.setState({
            subZone: event.target.value
        });
    }

    checkIfSubzoneAlreadyPresent = async (event) => {
        const subZoneValue = event.target.value
        const subZoneData = await axios(`${API_BASE_URL}/subZone/${sessionStorage.getItem("siteId")}/${subZoneValue}`);
        if (subZoneData.status === 200 ) {
            if (subZoneData.data.result.length > 0) {
                ShowToast(`This ${event.target.value} subzone is already created`, TYPE_SUCCESS, INTERVAL, this.props, "");
                this.setState({
                    subZone: ""
                })
            }
            return subZoneData;
        }
    }

    checkIfSubzoneAlreadyPresentWhileSubmit = async () => {
        const subZoneValue = this.state.subZone;
        const subZoneData = await axios.get(`${API_BASE_URL}/subZone/${sessionStorage.getItem("siteId")}/${subZoneValue}`);
        return subZoneData.data.result.length > 0;
    }

    isFormValid = () => {
        let isValid = true;
        let error = {}
        if (this.state.subZone === "") {
            isValid = false;
            error.subZone = "Please add subzone";
        }

        this.setState({
            error
        });

        return isValid;
    }

    addSubzoneHandler = async (event) => {
        if (this.isFormValid()) {
            this.setState({ isLoading: true });
            const requestData = {
                subZone: this.state.subZone,
                siteId: this.state.siteId,
                siteName: this.state.siteName
            }

            const isPresent = await this.checkIfSubzoneAlreadyPresentWhileSubmit();
            if (isPresent) {
                ShowToast(`This ${this.state.subZone} subzone is already created`, TYPE_SUCCESS, INTERVAL, this.props, "");
                this.setState({subZone: "", isLoading: false });
                return;
            }

            let roleResponse = null;
            if (this.state.subZoneId === null) {
                roleResponse = await axios.post(`${API_BASE_URL}/subzone`, requestData);
            }
            else {
                roleResponse = await axios.put(`${API_BASE_URL}/subzone/${this.state.subZoneId}`, requestData);
            }
            if (roleResponse.status === 200) {
                const subZoneList = await this.getSubzoneList();
                const tempSubzoneId = this.state.subZoneId;
                this.setState({
                    subZone: "",
                    subZoneId: null,
                    subZoneList: subZoneList.data.result,
                    buttonText: "Add Subzone",
                    isLoading: false
                }, () => {
                    if (tempSubzoneId === null) {
                        ShowToast('Subzone created Successfully', TYPE_SUCCESS, INTERVAL, this.props, "");
                    }
                    else {
                        ShowToast('Subzone updated successfully', TYPE_SUCCESS, INTERVAL, this.props, "");
                    }
                });
            }
        }
    }

    cancelSubzoneHandler = () => {
        window.location.reload();
    }

    handleEdit = (item) => {
        const subzoneListData = this.state.zoneList;
        let tempSubzoneFlag = false;
        const resultData = subzoneListData.map((subzone) => {
            const subZoneNames = Array.isArray(subzone.sub_zone_name) ? subzone.sub_zone_name : [subzone.sub_zone_name];

            if (subZoneNames.includes(item.sub_zone_name)) {
                ShowToast(`This subzone assigned to ${subzone.zone_name} zone`, TYPE_FAIL, INTERVAL, this.props, "");
                tempSubzoneFlag = true;
                return;
            }
            this.setState({
                subZoneId: item._id,
                subZone: item.sub_zone_name,
                buttonText: "Update"
            });
        })
        this.setState({
            subZoneUpdateButtonDisabaled: tempSubzoneFlag,
            subZoneDisabaled:tempSubzoneFlag
        })
       return resultData;
    }

    handleDelete = async (item) => {
        if (window.confirm("Are you sure you want to delete this subzone?")) {
            this.setState({ isLoading: true });

            const subzoneListData = this.state.zoneList;

            const assignedSubzone = subzoneListData.find((subzone) => {
                const subZoneNames = Array.isArray(subzone.sub_zone_name) ? subzone.sub_zone_name : [subzone.sub_zone_name];
                return subZoneNames.includes(item.sub_zone_name);
            });

            if (assignedSubzone) {
                ShowToast(`This subzone is assigned to ${assignedSubzone.zone_name} zone`, TYPE_FAIL, INTERVAL, this.props, "");
                this.setState({ isLoading: false });
                return;
            }

                let deleteResponse = await axios.delete(`${API_BASE_URL}/subzone/${item._id}`);
                if (deleteResponse.status === 200) {
                    const subZoneList = await this.getSubzoneList();
                    this.setState({
                        subZoneList: subZoneList.data.result,
                        isLoading: false
                    });
                    ShowToast('Subzone deleted successfully', TYPE_SUCCESS, INTERVAL, this.props, "");
                }
                else {
                    ShowToast('Something went wrong. Please delete again after sometime!', TYPE_FAIL, INTERVAL, this.props, "");
                }
            }
    }

        render() {
            return (
                <div className="main-content">
                    <div className="back">
                    <ul className="breadcrumb1">
                            <li><a href="/zone-subzone-page">Zone-Subzone Master</a></li>
                            {
                                this.state.subZoneId === null ? (
                                    <li>Add New Subzone</li>
                                ) : (
                                    <li>Edit Subzone</li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="role-wrapper">
                        <div>
                            <div className="input-div">
                                <div>
                                    <input className="form-control brand-font p-3 placeholderColor add-role-input" value={this.state.subZone} onChange={this.subZoneChange} onBlur={this.checkIfSubzoneAlreadyPresent} disabled={this.state.subZoneDisabaled} placeholder="Enter subzone" />
                                    <div className="text-danger error-msg">
                                        {this.state.error.subZone}
                                    </div>
                                </div>
                                <button className="brand-button ml-5 role-button" disabled={this.state.subZoneUpdateButtonDisabaled} onClick={this.addSubzoneHandler}>{this.state.buttonText}</button>
                                {this.state.subZoneId !== null && (
                                    <button className="brand-button ml-5 role-button" onClick={this.cancelSubzoneHandler}>Cancel</button>
                                )
                                }
                            </div>
                        </div>
                        {
                            this.state.isLoading ? (
                                <Spinner />
                            ) : (
                                <div className="mt-5 mb-5 border user-table-div">
                                    {
                                        this.state.subZoneList.length > 0 ? (
                                            <table className="table list-table role-table">
                                                <th className="pl1">Sub zone</th>
                                                <th>Actions</th>
                                                <tbody className="role-list-table">
                                                    {
                                                        this.state.subZoneList.map((subZoneItem) => {
                                                            return (
                                                                <tr key={subZoneItem._id}>
                                                                    <td className="role-td pl1">
                                                                        {subZoneItem.sub_zone_name}
                                                                    </td>
                                                                    <td className={"action-td center"}>
                                                                        <div className="action-div">
                                                                            <span onClick={() => this.handleEdit(subZoneItem)}><i className={`${EDIT_ICON}`} title="Edit subzone"></i></span>
                                                                            <span onClick={() => this.handleDelete(subZoneItem)}><i className={`${TRASH_ICON}`} title="Delete subzone"></i></span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className="text-center pt-2">
                                                <label>Subzone records not found!</label>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            )
        }
    }

    export default CreateSubzone
