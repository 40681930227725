import React, { useState, useContext, useEffect  } from "react";
import AuthContext from 'context/AuthProvider';
import Toast from 'light-toast';
import axios from 'axios';
import { API_BASE_URL} from 'assets/constants/Constants';
import { hasRoutePermission } from "components/Permission";
import { USER_ICON, ROUTE_ICON, SITE_ICON, METER_ICON, USERS_ICON, VCARD_ICON, LINE_CHART_ICON, BAR_ICON, TIMES_CIRCLE_ICON, SIGN_OUT_ICON, BILLING_ICON, SEARCH_ICON, SAFARI_ICON, HISTORY_ICON, CLEAR_ICON, ZONE_SUBZONE_ICON, CONFIG_ICON } from "assets/constants/Icons";
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent, SidebarFooter, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

function SideNavigationBar (props) {
    const authContext = useContext(AuthContext);
    const userDetail = JSON.parse(sessionStorage.getItem("userData"));
    const userRole = JSON.parse(sessionStorage.getItem("userRole"));
    const [menuCollapse, setMenuCollapse] = useState(true);
    const [meterList, setMeterList] = useState([]); 

    const editUserHandler = (userDetail) => {
        props.history.push({
            pathname: "/create-user-page",
            state: { ...userDetail, isFromProfile: true }
        });
    }

    const getAllMeters = async () => {
        return await axios(`${API_BASE_URL}/meter/site/${sessionStorage.getItem("siteId")}`);
    }

    useEffect(() => {
        const fetchMeterData = async () => {
            const meterData = await getAllMeters();
            setMeterList(meterData.data.result);
        };
        fetchMeterData();
    }, []);

    const meterTypes = meterList.map(item => item.meter_type);

    



     

    const menuIconClick = () => {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);

        // console.log(authContext.isAuthenticated());
        if (!authContext.isAuthenticated()) {
            Toast.fail('You are not logged in. Please Login!', 3000, () => {
                window.location.href = "/login-page";
            });
        }
    };

    return (
        <div className="side-navigation-bar">
            <div className="side-navigation-bar-hamburger-button" onClick={menuIconClick}>
                { ( menuCollapse ) && ( <span className={`${BAR_ICON} side-navigation-bar-icon`} aria-hidden="true"></span> ) }
                { ( !menuCollapse ) && ( <span className={`${TIMES_CIRCLE_ICON} side-navigation-bar-icon`} aria-hidden="true"></span> ) }
            </div>
            <div className="side-navigation-bar-content">
                <ProSidebar collapsed={menuCollapse} breakPoint="md" rtl={false}>
                    <SidebarHeader>
                        { (sessionStorage.getItem("userData") !== null) && (
                            <Menu iconShape="circle">
                                <MenuItem icon={<i className={`${USER_ICON} side-navigation-bar-icon`}></i>} onClick={ () => editUserHandler(userDetail) } title="Edit user information">
                                    <span>{ `Hi, ${userDetail.first_name} ` }</span>
                                    <span>{ `(${userRole.user_role})` }</span>
                                </MenuItem>
                            </Menu>
                        )}
                    </SidebarHeader>

                    <SidebarContent>
                        <Menu iconShape="square">
                            { hasRoutePermission("/route-page") && (
                                <SubMenu id="side-navigation-bar-sub-menu-route" icon={<i className={`${ROUTE_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Route">
                                    <MenuItem>
                                        <a className="side-navigation-bar-menu-title" href="master-route-page"> Route Master</a>
                                    </MenuItem>
                                    <MenuItem>
                                        <a className="side-navigation-bar-menu-title" href="route-transaction-page">Route Transaction</a>
                                    </MenuItem>
                                    <MenuItem>
                                        <a className="side-navigation-bar-menu-title" href="route-history">Route Collection  History</a>
                                    </MenuItem>
                                </SubMenu>
                            )}

                            {hasRoutePermission("/sites-page") && (
                                <SubMenu id="side-navigation-bar-sub-menu-route" icon={<i className={`${CONFIG_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Settings">
                                    <MenuItem>
                                    <a className="side-navigation-bar-menu-title" href="sites-page">Sites</a>
                                    </MenuItem>
                                    <MenuItem>
                                    <a className="side-navigation-bar-menu-title" href="zone-subzone-page">Zones and Subzones</a>
                                    </MenuItem>
                                </SubMenu>
                            )}

                            {hasRoutePermission("/meter-page") ? (
                                meterTypes.length > 1 ? (
                                    <SubMenu id="side-navigation-bar-sub-menu-user" icon={<i className={`${METER_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Meter">
                                        <MenuItem>
                                            <a className="side-navigation-bar-menu-title" href="meter-page">All Meters</a>
                                        </MenuItem>
                                        {meterTypes.includes("Flow") && (
                                            <MenuItem>
                                                <a className="side-navigation-bar-menu-title" href="flow-meter-page">Flow Meter</a>
                                            </MenuItem>
                                        )}
                                        {meterTypes.includes("Pressure") && (
                                            <MenuItem>
                                                <a className="side-navigation-bar-menu-title" href="pressure-meter-page">Pressure Meter</a>
                                            </MenuItem>
                                        )}
                                        {meterTypes.includes("Level") && (
                                            <MenuItem>
                                                <a className="side-navigation-bar-menu-title" href="level-meter-page">Level Meter</a>
                                            </MenuItem>
                                        )}
                                    </SubMenu>
                                ) : meterTypes.length === 1 ? (
                                    <SubMenu id="side-navigation-bar-sub-menu-user" icon={<i className={`${METER_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Meter">
                                        {meterTypes.includes("Flow") && (
                                            <MenuItem>
                                                <a className="side-navigation-bar-menu-title" href="flow-meter-page">Flow Meter</a>
                                            </MenuItem>
                                        )}
                                        {meterTypes.includes("Pressure") && (
                                            <MenuItem>
                                                <a className="side-navigation-bar-menu-title" href="pressure-meter-page">Pressure Meter</a>
                                            </MenuItem>
                                        )}
                                        {meterTypes.includes("Level") && (
                                            <MenuItem>
                                                <a className="side-navigation-bar-menu-title" href="level-meter-page">Level Meter</a>
                                            </MenuItem>
                                        )}
                                    </SubMenu>

                                ) : (
                                    <MenuItem icon={<i className={`${METER_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Meter">
                                        <a className="side-navigation-bar-menu-title" href="meter-page">Meter</a>
                                    </MenuItem>
                                )
                            ) : null}

                            {hasRoutePermission("/ami-amr-meter-reading-page") && (
                                <SubMenu id="side-navigation-bar-sub-menu-billing" icon={<i className={`${SAFARI_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Readings">
                                    <MenuItem>
                                        <a className="side-navigation-bar-menu-title" href="ami-amr-meter-reading-page">Ami / Amr Readings</a>
                                    </MenuItem>
                                    <MenuItem>
                                        <a className="side-navigation-bar-menu-title" href="flow-readings-page">Flow Readings</a>
                                    </MenuItem>
                                    <MenuItem>
                                        <a className="side-navigation-bar-menu-title" href="pressure-readings-page">Pressure Readings</a>
                                    </MenuItem>
                                    <MenuItem>
                                        <a className="side-navigation-bar-menu-title" href="level-readings-page">Level Readings</a>
                                    </MenuItem>
                                </SubMenu>
                            )}

                            { hasRoutePermission("/user-page") && (
                                <SubMenu id="side-navigation-bar-sub-menu-user" icon={<i className={`${USERS_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="User">
                                    <MenuItem>
                                        <a className="side-navigation-bar-menu-title" href="user-page"> User Master</a>
                                    </MenuItem>
                                    { hasRoutePermission("/roles-page") && (
                                        <MenuItem>
                                            <a className="side-navigation-bar-menu-title" href="roles-page">Role</a>
                                        </MenuItem>
                                    )}
                                </SubMenu>
                            )}

                            { hasRoutePermission("/customer-page") && (
                                <MenuItem icon={<i className={`${VCARD_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Customer">
                                    <a className="side-navigation-bar-menu-title" href="customer-page">Customer</a>
                                </MenuItem>
                            )}

                            { hasRoutePermission("/report-page") && (
                                <SubMenu id="side-navigation-bar-sub-menu-report" icon={<i className={`${LINE_CHART_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Report">
                                    <MenuItem>
                                        <a className="side-navigation-bar-menu-title" href="report-master-page"> Report Master</a>
                                    </MenuItem>
                                    <MenuItem>
                                        <a className="side-navigation-bar-menu-title" href="report-transaction-page">Report Transaction</a>
                                    </MenuItem>
                                </SubMenu>
                            )}

                            { hasRoutePermission("/billing-cycle-page") && (
                                <SubMenu id="side-navigation-bar-sub-menu-billing" icon={<i className={`${BILLING_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Billing">
                                    <MenuItem>
                                        <a className="side-navigation-bar-menu-title" href="billing-setup-page">Billing Setup</a>
                                    </MenuItem>
                                    <MenuItem>
                                        <a className="side-navigation-bar-menu-title" href="billing-cycle-page">Billing Cycle</a>
                                    </MenuItem>
                                </SubMenu>
                            )}

                            { hasRoutePermission("/sites-page") && (
                                <MenuItem icon={<i className={`${SEARCH_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Search by Transaction ID">
                                    <a className="side-navigation-bar-menu-title" href="search-transaction-id-page">Search by Transaction ID</a>
                                </MenuItem>
                            )}
                         
                            { hasRoutePermission("/meter-history-log") && (
                                <MenuItem icon={<i style={{fontStyle: "normal"}} className={`${HISTORY_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Meter History Log">
                                    <a className="side-navigation-bar-menu-title" href="meter-history-log">Meter History Log</a>
                                </MenuItem>
                            )}

                            { hasRoutePermission( "/clear-meter-history") && (
                                <MenuItem icon={<i style={{fontStyle: "normal"}} className={`${CLEAR_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Clear Meter History">
                                    <a className="side-navigation-bar-menu-title" href="clear-meter-history"> Clear Meter History</a>
                                </MenuItem>
                            )}
                            
                        </Menu>
                    </SidebarContent>

                    <SidebarFooter>
                        <Menu>
                            <MenuItem icon={<i className={`${SIGN_OUT_ICON} side-navigation-bar-icon`} aria-hidden="true"></i>} title="Logout" onClick={authContext.signOut}>
                                <span>Logout</span>
                            </MenuItem>
                        </Menu>
                    </SidebarFooter>
                </ProSidebar>
            </div>
        </div>
    );
};

export default SideNavigationBar;
