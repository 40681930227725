import React, { Component } from 'react'
import axios from 'axios';
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL, READER, CONFIG_SERVICE, SUPER_ADMIN } from 'assets/constants/Constants';
import Spinner from 'components/Spinner';
import AuthContext from 'context/AuthProvider';
import ShowToast from 'components/ShowToast';
import config from "../../config.json";
import { USER_ICON, LOCK_ICON } from 'assets/constants/Icons';

export class LoginPage extends Component {
    constructor(props) {
        super(props)
        let isSiteDropdownVisible = false;
        let backGroundImageChange = false;
        let showWarningMsg = false;
        localStorage.setItem("loginURL", window.location.href);
        console.log("this.props.match.params.clientID: ", this.props.match.params.clientID);
  
        if(this.props.match.params.clientID){
            isSiteDropdownVisible = false;
            backGroundImageChange = true;
        }
        else if(this.props.match.params.role === "superadmin"){
            isSiteDropdownVisible = true;
            backGroundImageChange = false;
        }
        else{
            isSiteDropdownVisible = false;
            backGroundImageChange = false;
            if(!this.props.match.params.clientID && this.props.match.params.role !== "superadmin"){
                showWarningMsg = true; 
            }
        }
        this.state = {
            isLoading: true,
            username: "",
            password: "",
            siteList: [],
            input: {},
            errors: {},
            brandLogo: "",
            selectedSiteId: "",
            isSiteDropdownVisible,
            siteBackgroundImage: "",
            backGroundImageChange,
            showWarningMsg,
            siteLogoMainImage:"",
            siteLogoMainImage: "",
            siteClientName: "",
            clientID: this.props.match.params.clientID,
            currentUrl: window.location.href
        }
    }
    
    componentDidMount = async () => {
        if(this.context.isAuthenticated()){
            window.location.href = "/user-page";
        }
        
        const siteData = await this.getAllSites();
        const siteIdUrl = sessionStorage.getItem("siteId")
        if (this.props.match.params.clientID) {
            const siteUrlData = await this.getUrlSite();
            const defaultImage = siteUrlData.data.result.defaultImage_uploadedImage;
            const siteBackgroundImage = defaultImage ? defaultImage.base64Data ? defaultImage.base64Data:defaultImage  : null;
            const clientName = siteUrlData.data.result.client_name
            const defaultLogoImage =  siteUrlData.data.result.client_logoImage_primary;
            const sitelogoImage = defaultLogoImage && defaultLogoImage.base64Data? defaultLogoImage.base64Data: null;
            this.setState({
                siteBackgroundImage: siteBackgroundImage,
                siteLogoMainImage : sitelogoImage,
                siteClientName: clientName,
                brandLogo: this.props.match.params.clientID? this.state.siteLogoMainImage !== null? this.state.siteLogoMainImage:this.context.BRAND_LOGO  : this.context.BRAND_LOGO,
                isLoading: false
            });
        }
        this.setState({
            siteList: siteData.data.result,
            brandLogo: this.props.match.params.clientID? this.state.siteLogoMainImage !== null? this.state.siteLogoMainImage:this.context.BRAND_LOGO  : this.context.BRAND_LOGO,
            isLoading: false
        }, () => {
            this.setSessionForSite();
        });
    
    }

    setSessionForSite = () => {
        if(this.props.match.params.clientID){
            sessionStorage.setItem("clientID", this.props.match.params.clientID);
            this.state.siteList.forEach((siteItem, index) => {
                if(this.props.match.params.clientID === siteItem.client_id){
                    sessionStorage.setItem("siteName", siteItem.site_name);
                    sessionStorage.setItem("siteId", siteItem._id);
                    sessionStorage.setItem("ClientName", siteItem.client_name);
                    this.setState({
                        selectedSiteId: siteItem._id,
                    });
                }
            });
        }
    }

    getAllSites = async () => {
        return await axios(`${API_BASE_URL}/site`);
    }
    getUrlSite = async () => {
        return await axios(`${API_BASE_URL}/site/${this.state.clientID}`);
    }

    renderSiteOptions = () => {
        if(this.state.siteList.length > 0){
            return this.state.siteList.map((siteItem, index) => {
                return(
                    <option value={ siteItem._id } key= { siteItem._id } className = "option-text-color"> { siteItem.site_name } </option>
                )
            });
        }
        else{
            return "<option> No Data Found</option>"
        }
    }

    siteChangeHandler = (event) => {
        console.log("event:;",event)
        let index = event.nativeEvent.target.selectedIndex;
        sessionStorage.setItem("siteName", event.nativeEvent.target[index].text);
        sessionStorage.setItem("siteId", event.target.value);
        this.setState({
            selectedSiteId: event.target.value
        });
    }

    handleInputChange = (event) => {
        let input = this.state.input;
        input[event.target.name] = event.target.value;
        this.setState({
            input,
        });
    }

    formValidate = () => {
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if(!sessionStorage.getItem("siteId") || sessionStorage.getItem("siteId") === "Select Site"){
            isValid = false;
            errors["site"] = "Please select site.";
        }

        if (!input["userName"]) {
            isValid = false;
            errors["userName"] = "Please enter your username.";
        }

        if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter your password.";
        }

        this.setState({
            errors: errors,
        });

        return isValid;
    }

    handleSignIn = async (event) => {
        event.preventDefault();
        try {
            if(this.formValidate()){
                this.setState({
                    isLoading: true
                });
                const responseData = await this.context.signIn(this.state.input.userName, this.state.input.password, this.state.selectedSiteId);
                if(responseData.status === 200){
                    console.log("responseData: ", responseData);
                    if(responseData !== null){
                        if(responseData.data.result.success){
                            const userRole = await axios(`${API_BASE_URL}/role/${responseData.data.result.result.role}`);
                            ShowToast('Login successful!', TYPE_SUCCESS, INTERVAL, this.props, ""); 
                            if(userRole.data.result.user_role === READER){
                                this.props.history.push({
                                    pathname: "/route-transaction-page",
                                });
                            }
                            else if(userRole.data.result.user_role === CONFIG_SERVICE){
                                this.props.history.push({
                                    pathname: "/meter-page",
                                });
                            }
                            else{
                                this.props.history.push({
                                    pathname: "/home-page",
                                });
                            }
                        }
                        else{
                            this.setState({
                                isLoading: false
                            }, () => {
                                ShowToast(responseData.data.message, TYPE_FAIL, INTERVAL, this.props); 
                            });
                        }
                    }
                    else{
                        this.setState({
                            isLoading: false
                        }, () => {
                            ShowToast('Something went wrong. Please try after some time or contact site administrator', TYPE_FAIL, INTERVAL, this.props); 
                        });
                        
                    }
                }
                else{
                    this.setState({
                        isLoading: false
                    }, () => {
                        ShowToast('Something went wrong. Please try after some time or contact site administrator', TYPE_FAIL, INTERVAL, this.props); 
                    });
                }
            }
        } catch (error) {
            this.setState({
                isLoading: false
            }, () => {
                ShowToast('Authentication failed! Invalid username or password.', TYPE_FAIL, INTERVAL, this.props); 
            });
        }
    }

    render() {
        const value = this.state.siteBackgroundImage ? this.state.siteBackgroundImage.base64Data ? this.state.siteBackgroundImage.base64Data  : this.state.siteBackgroundImage  : null;
        const brandLogoImage = this.state.brandLogo
     
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            const backgroundImageStyle = value ? {
                backgroundImage: `url(${value === "power plant" ? config.POWER_PLANT : value === "electricity plant" ? config.ELECTRICITY_PLANT : value === "hydro plant" ? config.HYDRO_PLANT    : value})`,
                height: '100vh',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }
            : {
                backgroundImage: `url(${config.BRAND_LOGIN_PAGE_BG})`,
                height: '100vh',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            };
            return ( 
                //<div className={`outer_login_div ${this.context.styles.outerLoginBg}`}>
                <div className={`outer_login_div ${this.state.backGroundImageChange? 'customBackgroundStyle'   : this.context.styles.outerLoginBg}`} style={this.state.backGroundImageChange ? backgroundImageStyle : {}} >
                    {
                        this.state.showWarningMsg ? (
                            <React.Fragment>
                                <div className="main_div">
                                    <div className="brand_logo_view">
                                        <img alt="Brand Logo" className="img-no-padding img-responsive" src={this.state.brandLogo} />
                                    </div>
                                    <div className="login-warning-msg">
                                        <label>Please use site specific URL for login shared by your Administrator.</label>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="main_div">
                                <div className="brand_logo_view">
                                    <img alt="Brand Logo" className="img-no-padding img-responsive" src={this.state.brandLogo} />
                                </div>
                                <div className="title-client-name">{this.state.siteClientName}</div>
                                <div className="title-login">Login</div>
                                <form action="!#">
                                    {
                                        this.state.isSiteDropdownVisible && (
                                            <React.Fragment>
                                                <div className="input_box">
                                                    <select className="login-dropdown p-3 placeholderColor" name="site" onChange={this.siteChangeHandler}>
                                                        <option>Select Site</option>
                                                        {
                                                            this.renderSiteOptions()
                                                        }
                                                    </select>
                                                </div>
                                                <div className="text-danger ml-2 error_msg">
                                                    {this.state.errors.site}
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                    <div className="input_box">
                                        <input type="text" name="userName" placeholder="Enter Your Username" onChange={this.handleInputChange} />
                                        <div className="icon"><i className={`${USER_ICON} icon`}></i></div>
                                    </div>
                                    <div className="text-danger ml-2 error_msg">
                                        {this.state.errors.userName}
                                    </div>
                                    <div className="input_box">
                                        <input type="password" name="password" placeholder="Enter Your Password" onChange={this.handleInputChange} />
                                        <div className="icon"><i className={`${LOCK_ICON} icon`}></i></div>
                                    </div>
                                    <div className="text-danger ml-2 error_msg">
                                        {this.state.errors.password}
                                    </div>
                                    <div className="option_div">
                                        <div></div>
                                        <div className="forget_div">
                                            <a href="/forget-password">Forgot password?</a>
                                        </div>
                                    </div>
                                    <div className={`input_box ${this.context.styles.brandButton}`}>
                                        <button onClick={this.handleSignIn}>Login</button>
                                    </div>
                                </form>
                            </div>
                        )
                    }
                </div>
            )
        }
    }
}

LoginPage.contextType = AuthContext;
export default LoginPage
