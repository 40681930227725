import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL, TYPE_SUCCESS, INTERVAL, TYPE_FAIL } from "assets/constants/Constants";
import { ZONE_SUBZONE_ICON, TRASH_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';
import Select from 'react-select';

export class CreateZoneAndSubZonePage extends Component {
    constructor(props) {
        super(props)

        const editData = this.props.history.location.state;
        const tempSubZone = editData && Array.isArray(editData.subZone) 
        ? editData.subZone
            .filter(item => item.trim() !== "") 
            .map(item => ({ label: item, value: item })) 
        : [];

        this.state = {
            isLoading: true,
            tempSubZone: "",
            zoneId: editData === null || editData === undefined ? null : editData._id,
            meterId: "",
            zone: editData === null || editData === undefined ? "" : editData.zone,
            previousZone: editData === null || editData === undefined ? "" : editData.zone,
            subZone: editData === null || editData === undefined || editData === "" ? "" : tempSubZone,
            parameteres: "",
            meter: "",
            subzoneSelect: [],
            parameteresOptions: [
                { label: "Flow", value: "flow" },
                { label: "Pressure", value: "pressure" },
                { label: "Level", value: "level" },
                { label: "Tempereture", value: "tempereture" }],
            siteId: sessionStorage.getItem("siteId"),
            siteName: sessionStorage.getItem("siteName"),
            meterList: [],
            zoneAndSubzoneList: [],
            subZoneList: [],
            parameteresDisabaled: true,
            metersDisabaled: true,
            error: {}
        }
    }


    async componentDidMount() {
        const meterList = await this.getAllMeters();
        const zoneAndSubzoneList = await this.getZoneSubzoneList()
        const subZoneList = await this.getSubzoneList()
        this.setState({
            isLoading: false,
            meterList: meterList.data.result,
            zoneAndSubzoneList: zoneAndSubzoneList.data.result,
            subZoneList: subZoneList.data.result
        });

    }

    getSubzoneList = async () => {
        return await axios.get(`${API_BASE_URL}/subzone/${sessionStorage.getItem("siteId")}`);
    }

    meterOptionList = (meterList, subzone, parameteres, zone) => {
        if (!parameteres || !parameteres.label) {
            console.error("Invalid parameteres:", parameteres);
            return [];
        }
        const meterAllList = meterList.map((item) => {
            if (parameteres.label === item.meter_type && subzone.label === item.subZone && zone === item.zone) {
                return { label: item.serial_number, value: item.serial_number, meterId: item._id };
            } else {
                return null;
            }
        }).filter(item => item !== null);
        return meterAllList;
    }

    subzoneList = (zoneAndSubzoneList, zone) => {
        const updatedSubzonelist = zoneAndSubzoneList.filter(item => zone === item.zone_name).flatMap(item => {
            return item.sub_zone_name.filter(subZone => subZone.trim() !== "").map(subZone => ({
                label: subZone,
                value: subZone
            }));
        });

        return updatedSubzonelist;
    };

    subzoneListCreated = () => {
        const subZoneListCreatedValue = this.state.subZoneList.map((item) => {
            return item.sub_zone_name
        })
        const zoneAndSubzoneList = this.state.zoneAndSubzoneList.map((item) => {
            return item.sub_zone_name
        })
        const arrayData = zoneAndSubzoneList.flat();
        const remainingSubZoneList = subZoneListCreatedValue.filter((value) => {
            return !arrayData.includes(value);
        });
        const dropdownOptions = remainingSubZoneList.map((value) => {
            return {
                value: value,
                label: value
            };
        });
        return dropdownOptions;
    }

    checkIfZoneAlreadyPresentWhileSubmit = async () => {
        const zoneValue = this.state.zone;
        const zoneData = await axios.get(`${API_BASE_URL}/zone/subZone/${sessionStorage.getItem("siteId")}/${zoneValue}`);
        return zoneData.data.result.length > 0;
    }

    parameteresOptions = (meterList, subzoneSelect, zone) => {
        if (!subzoneSelect || !subzoneSelect.label) {
            console.error("Invalid subzoneSelect:", subzoneSelect);
            return [];
        }

        const meterParametersOptions = meterList.filter(item => subzoneSelect.label === item.subZone && zone === item.zone).map(item => ({
            label: item.meter_type,
            value: item.meter_type
        }));

        const uniqueMeterParametersOptions = Array.from(
            new Map(meterParametersOptions.map(item => [item.label, item])).values()
        );


        if (uniqueMeterParametersOptions.length > 0) {
            return uniqueMeterParametersOptions;
        } else {
            ShowToast('Meters not found based on selected subzone', TYPE_FAIL, INTERVAL, this.props, "");
            this.setState({
                subzoneSelect: "",
                parameteres: "",
                parameteresDisabaled: true,
                metersDisabaled: true,
                meter: ""
            })
            return [];
        }
    };

    subzoneChange = (event) => {
        this.setState({
            subzoneSelect: event,
            parameteresDisabaled: false
        })
    }


    getAllMeters = async () => {
        return await axios(`${API_BASE_URL}/meter/site/${sessionStorage.getItem("siteId")}`);
    }

    getZoneSubzoneList = async () => {
        return await axios.get(`${API_BASE_URL}/zone/subzone/${this.state.siteId}`);
    }

    zoneChange = (event) => {
        this.setState({
            zone: event.target.value
        });

    }

    zoneDisabale = () => {
        if(this.state.zoneId !== null){
        const meterLists = this.state.meterList.map(item => item.zone);
        const zoneLists = this.state.zoneAndSubzoneList.map(item => item.zone_name)
        const zoneName = this.state.zone
        const zoneNameMatch = meterLists.includes(zoneName);
        return zoneNameMatch;
        }
    }

    subZoneChange = (event) => {
      
        if (event.length < this.state.subZone.length && this.state.subZone.length > 0) {
            const removedItem = this.state.subZone.find(option =>
                !event.some(selected => selected.value === option.value)
            );

            if (removedItem) {
                const removedItemValue = removedItem.value;
                const zoneName = this.state.zone;

                const assignedMeter = this.state.meterList.find(item =>
                    removedItemValue === item.subZone && zoneName === item.zone
                );

                if (assignedMeter) {
                    ShowToast(`This subzone is assigned to this meter ${assignedMeter.serial_number}`, TYPE_SUCCESS, INTERVAL, this.props, "");
                    return;
                }
            }
        }
        this.setState({ subZone: event });
    };





    parameteresChange = (event) => {
        this.setState({
            parameteres: event,
            meter: "",
            metersDisabaled: false
        });
    }

    meteresChange = (event) => {
        this.setState({
            meter: event,
            meterId: event.meterId
        });
    }



    deleteMeter = async (meterId, meter) => {

        if (meter) {
            try {
                if (window.confirm("Are you sure you want to delete this meter?")) {
                    this.setState({ isLoading: true });

                    const userDetails = JSON.parse(sessionStorage.getItem("userData"));
                    const userFirstName = userDetails.first_name;
                    const userLastName = userDetails.last_name;
                    const userEmailId = userDetails.email_id;
                    const userName = userDetails.username;
                    const userId = userDetails._id;

                    const requestData = {
                        siteId: sessionStorage.getItem("siteId"),
                        SerialNumber: this.state.meter,
                        userFirstName,
                        userLastName,
                        userEmailId,
                        userName,
                        userId,
                        reason: "Meter has been deleted",
                    }

                    console.log(JSON.stringify(requestData));
                    // let deleteResponse = await axios.delete(`${ API_BASE_URL }/meter/${item.meterId}`);
                    let deleteResponse = await axios.post(`${API_BASE_URL}/meter/deleteMeter/${meterId}`, requestData);
                    if (deleteResponse.data.success) {
                        await axios.delete(`${API_BASE_URL}/route_meter_map/meter_delete/${meterId}`);
                        if (deleteResponse.status === 200) {
                            let fetchResponse = await this.getAllMeters();
                            this.setState({
                                meterList: fetchResponse.data.result,
                                isLoading: false
                            });
                            ShowToast('Meter deleted successfully', TYPE_SUCCESS, INTERVAL, this.props, "zone-subzone-page");
                        }
                        else {
                            this.setState({
                                isLoading: false
                            });
                            ShowToast('Something went wrong. Please delete again after sometime!', TYPE_FAIL, INTERVAL, this.props, "");
                        }
                    }
                    else {
                        this.setState({
                            isLoading: false
                        });
                        ShowToast(deleteResponse.data.message, TYPE_FAIL, INTERVAL, this.props, "");
                    }
                }
            } catch (error) {
                this.setState({
                    isLoading: false
                });
                console.log("Error: ", error.message);
                ShowToast('Something went wrong. Please delete again after sometime!', TYPE_FAIL, INTERVAL, this.props, "");
            }
        }
        else {
            ShowToast('Selecet meter !', TYPE_FAIL, INTERVAL, this.props, "");
        }

    }



    isFormValid = () => {
        let isValid = true;
        let error = {};

        if (!this.state.zone) {
            isValid = false;
            error.zone = "Please enter zone";
        }

        this.setState({
            error
        });

        return isValid;
    }


    cancelHandler = (event) => {
        this.props.history.push("/zone-subzone-page");
    }



    addZoneHandler = async (event) => {
        event.preventDefault();
        if (this.isFormValid()) {
            this.setState({ isLoading: true });
            const requestData = {
                zone: this.state.zone,
                zoneId: null,
                previousZone: this.state.previousZone,
                subZone: this.state.subZone,
                siteId: this.state.siteId,
                siteName: this.state.siteName
            }

            const isPresent = await this.checkIfZoneAlreadyPresentWhileSubmit();

            if (isPresent){
                if (this.state.zoneId === null) {
                    ShowToast(`This ${this.state.zone} zone is already created`, TYPE_SUCCESS, INTERVAL, this.props, "");
                    this.setState({ zone: "",isLoading: false });
                    return;
                }
            }

            let zoneResponse = null;
            if (this.state.zoneId === null) {
                zoneResponse = await axios.post(`${API_BASE_URL}/zone/subzone/`, requestData);
            }
            else {
                zoneResponse = await axios.put(`${API_BASE_URL}/zone/subzone/${this.state.zoneId}/${this.state.siteId}`, requestData);
            }
            if (zoneResponse.status === 200) {
                const tempZoneId = this.state.zoneId;
                this.setState({
                    zone: "",
                    subZone: "",
                    zoneId: null,
                    isLoading: false
                }, () => {
                    if (tempZoneId === null) {
                        ShowToast('zone and subzone created Successfully', TYPE_SUCCESS, INTERVAL, this.props, "/zone-subzone-page");
                    }
                    else {
                        ShowToast('zone and subzone updated successfully', TYPE_SUCCESS, INTERVAL, this.props, "/zone-subzone-page");
                    }
                });
            }
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li><a href="/zone-subzone-page">Zone and Subzone Master</a></li>
                            {
                                this.state.zoneId === null ? (
                                    <li>Add New Zone and Subzone</li>
                                ) : (
                                    <li>Edit Zone and Subzone</li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="add-meter-main-view">
                        <div className="mx-auto text-left border create-meter-div">
                            <form className="pt-1">
                                <div className="meter-icon-wrapper">
                                    <h4>{this.state.zoneId === null ? "Add New Zone and Sub Zone" : "Edit Zone and Sub Zone"}</h4>
                                    <i className={`${ZONE_SUBZONE_ICON} list-table-icon`}></i>
                                </div>

                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Zone name <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={this.state.zone} onChange={this.zoneChange} disabled={this.zoneDisabale()} placeholder="Enter zone name" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.zone}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Sub Zone name</label>
                                        <Select
                                            placeholder={`Select sub zone name`}
                                            value={this.state.subZone}
                                            options={this.subzoneListCreated()}
                                            onChange={this.subZoneChange}
                                            isSearchable={true}
                                            isMulti={true}
                                        />
                                    </div>
                                </div>
                                {
                                    this.state.zoneId !== null && (
                                        <div className="row">
                                            <div className="input-label-group col-md-6">
                                                <label className="form-label">Sub zones</label>
                                                <Select
                                                    placeholder={`Select subzone`}
                                                    value={this.state.subzoneSelect}
                                                    options={this.subzoneList(this.state.zoneAndSubzoneList, this.state.zone)}
                                                    onChange={this.subzoneChange}
                                                    isSearchable={true}
                                                />
                                            </div>
                                            <div className="input-label-group col-md-6">
                                                <label className="form-label">Parameters<span className="text-danger"> *</span></label>
                                                <Select
                                                    placeholder={`Select parameteres`}
                                                    value={this.state.parameteres}
                                                    options={this.parameteresOptions(this.state.meterList, this.state.subzoneSelect, this.state.zone)}
                                                    onChange={this.parameteresChange}
                                                    isSearchable={true}
                                                    isDisabled={this.state.parameteresDisabaled}
                                                />
                                            </div>
                                            <div className="input-label-group col-md-5">
                                                <label className="form-label">meter<span className="text-danger"> *</span></label>
                                                <div style={{ display: "flex" }}>
                                                    <Select
                                                        placeholder={`Select Meter`}
                                                        value={this.state.meter}
                                                        options={this.meterOptionList(this.state.meterList, this.state.subzoneSelect, this.state.parameteres, this.state.zone)}
                                                        onChange={this.meteresChange}
                                                        isSearchable={true}
                                                        isDisabled={this.state.metersDisabaled}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                minWidth: '385px', 
                                                            }),
                                                        }}
                                                    /><span onClick={() => this.deleteMeter(this.state.meterId, this.state.meter)}>
                                                        <i style={{ marginLeft: '35px' }} className={`${TRASH_ICON} list-table-icon`}></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="row mt-4 mb-5">
                                    <div className="col-md-6">
                                        <button className="brand-button" onClick={this.addZoneHandler}>Submit</button>
                                        <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default CreateZoneAndSubZonePage
