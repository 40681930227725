import React, { Component } from 'react';
import {TYPE_FAIL, INTERVAL} from 'assets/constants/Constants';
import {CAMERA_ICON} from 'assets/constants/Icons';
import ShowToast from 'components/ShowToast';
import { defaultFormatDateTime,validateIsoDateFormat,validateBitStatusAlarms, totalizerUnit, flowUnit,setFlowUnit151,setTotalizerUnit151, getAlarmTypeFor151BO,setTotalizerUnit153,setFlowUnit153 } from "lib/utils"
import MaterialTable from "material-table";

export class ReadingListTable extends Component {
    constructor (props) {
        super (props)

        this.state = {
            viewReadingImage: false,
            readingImageSource: "",
            meterApplicationType: this.props.meterApplicationType,
        }
    }

    viewImage = (readingImage) => {
        if (readingImage !== "" && readingImage !== undefined) {
            this.setState({
                viewReadingImage: true,
                readingImageSource: readingImage
            });
        }
        else {
            ShowToast("Image not uploaded yet.", TYPE_FAIL, INTERVAL, null, "");
        }
    }

    render() {
        console.log("!this.props.pageName::",this.props.pageName)
        return (
            <div>
                {
                    this.props.pageName === "AMI Readings" && (
                        <div>
                            <MaterialTable
                                columns={
                                    [
                                        { title: "Serial Number", field: "serialNumber", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Web Receive Time", field: "webReceiveTime", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Route", field: "route", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                                        { title: this.props.pageName === "AMI Readings" ? "Gateway" : "Aqualink", field: "gateway", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Reader", field: "reader", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Unit", field: "unit", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Meter Status", field: "meterStatus", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Alarm Type", field: "alarmType", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Meter Time", field: "meterTime", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Readings", field: "readings", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Photo", field: "photo", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 }, },
                                        { title: "RSSI", field: "rssi", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                                        { title: "SSR", field: "ssr", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                                    ]
                                }
                                data={[
                                    ...this.props.readingRadarList.map((readingItem) => {
                                        let meterStatusClass = "unread";
                                        if (readingItem.meter_status === "Manual") {
                                            meterStatusClass = "manual";
                                        }
                                        else if (readingItem.meter_status === "Auto+Alarm") {
                                            meterStatusClass = "alarm";
                                        }
                                        else if (readingItem.meter_status === "Auto Read") {
                                            meterStatusClass = "read";
                                        }
                                        return {
                                            serialNumber: readingItem.meter_serial_no,
                                            webReceiveTime: defaultFormatDateTime(readingItem.created_date_time),
                                            route: !this.props.pageName && (readingItem.route_name),
                                            gateway: readingItem.network_info,
                                            reader: !this.props.pageName && (readingItem.assigned_user_id),
                                            unit: readingItem.level_distance_unit !== null ? (readingItem.level_distance_unit === "0" ? "meter" : readingItem.level_distance_unit === "1" ? "cm" : readingItem.level_distance_unit === "2" ? "mm" : "NA") : "NA",
                                            meterStatus: <span className={` ${meterStatusClass}`}>{readingItem.meter_status}</span>,
                                            alarmType: validateBitStatusAlarms(readingItem.alarm_type),
                                            meterTime: validateIsoDateFormat(readingItem.network_time_stamp) ? defaultFormatDateTime(readingItem.network_time_stamp) : readingItem.network_time_stamp,
                                            readings: readingItem.level_distance_reading ? readingItem.level_distance_reading : "NA",
                                            photo: <i className={(readingItem.reading_image !== "" && readingItem.reading_image !== undefined) ? `${CAMERA_ICON} camera-icon` : `${CAMERA_ICON} gray-icon`} title="Reading Image" onClick={() => this.viewImage(readingItem.reading_image)} />,
                                            rssi: "NA",
                                            ssr: "NA",
                                        }
                                    }),
                                    ...this.props.readingList.map((readingItem) => {
                                        let meterStatusClass = "unread";
                                        if (readingItem.meter_status === "Manual") {
                                            meterStatusClass = "manual";
                                        }
                                        else if (readingItem.meter_status === "Auto+Alarm") {
                                            meterStatusClass = "alarm";
                                        }
                                        else if (readingItem.meter_status === "Auto Read") {
                                            meterStatusClass = "read";
                                        }
                                        return {
                                            serialNumber: readingItem.meter_serial_no,
                                            webReceiveTime: defaultFormatDateTime(readingItem.created_date_time),
                                            route: !this.props.pageName && (readingItem.route_name),
                                            gateway: readingItem.gway_device_id,
                                            reader: !this.props.pageName && (readingItem.assigned_user_id),
                                            unit: readingItem.unit !== null ? (readingItem.unit === "0" || readingItem.unit === "m3" ? "m3" : readingItem.unit === "1" || readingItem.unit === "L" ? "L" : readingItem.unit === "2" || readingItem.unit === "GAL" ? "GAL" : readingItem.unit === "3" || readingItem.unit === "ft3" ? "ft3" : "NA") : "NA",
                                            meterStatus: <span className={` ${meterStatusClass}`}>{readingItem.meter_status}</span>,
                                            alarmType: validateBitStatusAlarms(readingItem.alarm_type),
                                            meterTime: validateIsoDateFormat(readingItem.receive_date_time) ? defaultFormatDateTime(readingItem.receive_date_time) : readingItem.receive_date_time,
                                            readings: readingItem.meter_reading ? readingItem.meter_reading : "NA",
                                            photo: <i className={(readingItem.reading_image !== "" && readingItem.reading_image !== undefined) ? `${CAMERA_ICON} camera-icon` : `${CAMERA_ICON} gray-icon`} title="Reading Image" onClick={() => this.viewImage(readingItem.reading_image)} />,
                                            rssi: readingItem.field_strength_rssi !== undefined ? readingItem.field_strength_rssi : "NA",
                                            ssr: readingItem.snr !== undefined ? readingItem.snr : "NA",
                                        }
                                    })
                                ]}
                                options={{
                                    rowStyle: (rowData, index) => ({
                                        backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                                    }),
                                    sorting: true,
                                    headerStyle: {
                                        color: "black",
                                        fontSize: 15,
                                        fontWeight: 'bolder'
                                    },
                                    pageSize: 10,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    columnsButton: true,
                                }}
                            />
                        </div>
                    )
                }
                {
                    this.props.pageName === "Flow Readings" && (
                        console.log("meterApplicationType::",this.state.meterApplicationType),
                        <div>
                            <MaterialTable
                                columns={
                                    [
                                        { title: "SN", field: "sn", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Serial Number", field: "serialNumber", cellStyle: { cellWidth: '0% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Meter Date and Time", field: "dateandtime", cellStyle: { cellWidth: '10% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Totalizer", field: "totalizer", cellStyle: { cellWidth: '10% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Flowrate", field: "flowrate", cellStyle: { cellWidth: '10% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Status", field: "status", cellStyle: { cellWidth: '10% !important', color: '#000000', fontSize: 13 } },
                                        { title: "Network Timestamp", field: "timestamp", cellStyle: { cellWidth: '10% !important', color: '#000000', fontSize: 13 } },
                                    ]
                                }
                                data={[
                                    ...this.props.readingList.map((readingItem, index) => {
                                        let meterStatusClass = "unread";
                                        if (readingItem.meter_status === "Manual") {
                                            meterStatusClass = "manual";
                                        }
                                        else if (readingItem.meter_status === "Auto+Alarm") {
                                            meterStatusClass = "alarm";
                                        }
                                        else if (readingItem.meter_status === "Auto Read") {
                                            meterStatusClass = "read";
                                        }
                                        return {
                                            sn: (index + 1),
                                            serialNumber: readingItem.meter_serial_no,
                                            dateandtime: validateIsoDateFormat(readingItem.start_time_stamp) ? defaultFormatDateTime(readingItem.start_time_stamp) : readingItem.start_time_stamp,
                                            totalizer :  this.state.meterApplicationType === "Forward Totalizer" 
                                                ? (readingItem.forward_totalizer !== undefined  || readingItem.forward_totalizer !== null || readingItem.forward_totalizer !== "" ? readingItem.device_type === "151BO" ? setTotalizerUnit151(readingItem.forward_totalizer,readingItem.totalizer_unit) : setTotalizerUnit153(readingItem.forward_totalizer,readingItem.totalizer_unit) : "NA") 
                                                : this.state.meterApplicationType === "Reverse Totalizer" 
                                                ? (readingItem.reverse_totalizer !== undefined  || readingItem.reverse_totalizer !== null || readingItem.reverse_totalizer !== ""  ? readingItem.device_type === "151BO" ? setTotalizerUnit151(readingItem.forward_totalizer,readingItem.totalizer_unit) : setTotalizerUnit153(readingItem.forward_totalizer,readingItem.totalizer_unit)  : "NA") 
                                                : this.state.meterApplicationType === "Diffrence Totalizer" 
                                                ? (readingItem.totalizer_diffrence !== undefined || readingItem.totalizer_diffrence  !== null || readingItem.totalizer_diffrence  !== ""  ? readingItem.device_type === "151BO" ? setTotalizerUnit151(readingItem.forward_totalizer,readingItem.totalizer_unit) : setTotalizerUnit153(readingItem.forward_totalizer,readingItem.totalizer_unit)  : "NA") 
                                                : "NA",
                                            flowrate: readingItem.flow_rate ? readingItem.device_type === "151BO" ?   setFlowUnit151(readingItem.flow_rate, readingItem.flow_unit ) : setFlowUnit153(readingItem.flow_rate ,readingItem.flow_unit ) : "NA",
                                            status: readingItem.flow_rate ?  readingItem.device_type === "151BO" ? getAlarmTypeFor151BO(readingItem.alaram_type)  : validateBitStatusAlarms(readingItem.alaram_type) : "NA",
                                            timestamp: validateIsoDateFormat(readingItem.network_time_stamp) ? defaultFormatDateTime(readingItem.network_time_stamp) : readingItem.network_time_stamp,
                                        }
                                    })
                                ]}
                                options={{
                                    rowStyle: (rowData, index) => ({
                                        backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                                    }),
                                    sorting: true,
                                    headerStyle: {
                                        color: "black",
                                        fontSize: 15,
                                        fontWeight: 'bolder'
                                    },
                                    pageSize: 10,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    columnsButton: true,
                                }}
                            />
                        </div>
                    )}

                 {this.props.pageName === "Level Readings" && (
                        <div>
                          <MaterialTable
                            columns={[
                              {
                                title: "SN",
                                field: "sn",
                                cellStyle: {
                                  cellWidth: "10% !important",
                                  color: "#000000",
                                  fontSize: 13,
                                },
                              },
                              {
                                title: "Serial Number",
                                field: "serialNumber",
                                cellStyle: {
                                  cellWidth: "10% !important",
                                  color: "#000000",
                                  fontSize: 13,
                                },
                              },
                              {
                                title: "Date and Time",
                                field: "dateandtime",
                                cellStyle: {
                                  cellWidth: "10% !important",
                                  color: "#000000",
                                  fontSize: 13,
                                },
                              },
                              {
                                title: "Level",
                                field: "level",
                                cellStyle: {
                                  cellWidth: "10% !important",
                                  color: "#000000",
                                  fontSize: 13,
                                },
                              },
                              {
                                title: "Time Stamp",
                                field: "timestamp",
                                cellStyle: {
                                  cellWidth: "10% !important",
                                  color: "#000000",
                                  fontSize: 13,
                                },
                              },
                              {
                                title: "Status",
                                field: "status",
                                cellStyle: {
                                  cellWidth: "10% !important",
                                  color: "#000000",
                                  fontSize: 13,
                                },
                              },
                            ]}
                            data={[
                              ...this.props.readingList.map((readingItem, index) => {
                                console.log("readingItem-level",readingItem)
                                let meterStatusClass = "unread";
                                if (readingItem.meter_status === "Manual") {
                                  meterStatusClass = "manual";
                                } else if (readingItem.meter_status === "Auto+Alarm") {
                                  meterStatusClass = "alarm";
                                } else if (readingItem.meter_status === "Auto Read") {
                                  meterStatusClass = "read";
                                }
                                return {
                                  sn: index + 1,
                                  serialNumber: readingItem.meter_serial_no,
                                  dateandtime: validateIsoDateFormat(readingItem.network_time_stamp )? defaultFormatDateTime(readingItem.network_time_stamp) : readingItem.network_time_stamp,
                                  level: readingItem.level_distance_reading,
                                  timestamp: validateIsoDateFormat(readingItem.created_date_time )? defaultFormatDateTime(readingItem.created_date_time) : readingItem.created_date_time,
                                  status: "High",
                                };
                              }),
                            ]}
                            options={{
                              rowStyle: (rowData, index) => ({
                                backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                              }),
                              sorting: true,
                              headerStyle: {
                                color: "black",
                                fontSize: 15,
                                fontWeight: "bolder",
                              },
                              pageSize: 10,
                              pageSizeOptions: [10, 20, 50, 100],
                              columnsButton: true,
                            }}
                          />
                        </div>
                      )}
                {this.state.viewReadingImage && (
                    <div className="custom_modal">
                        <div className="modal_body">
                            <div className="row">
                                <div className="col-md-10">
                                    <h4 className="modal_title pagetitle">View Meter Reading Image</h4>
                                </div>
                                <div className="col-md-2">
                                    <button type="button" className="close text-right" style={{ fontSize: 20 }} onClick={(e) => this.setState({ viewReadingImage: false })}>&times;</button>
                                </div>
                                {(this.state.readingImageSource.includes("png") || this.state.readingImageSource.includes("jpg") || this.state.readingImageSource.includes("jpeg")) ? (
                                    <embed src={this.state.readingImageSource} title="photo" width="100%" height="auto" />
                                ) : (
                                    <embed src={this.state.readingImageSource} title="photo" width="100%" height="auto" style={{ height: '400px' }} />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default ReadingListTable
