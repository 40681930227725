import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Select from 'react-select';
import { MAP_MARKER_ICON } from "assets/constants/Icons";
import { PieChart, LineChartAllMeters, LineChartAllMetersBig, WrappedMapAllMeters, pieDataOne, pieDataTwo, pieDataThree, pieDataFour, lineDataOne, lineDataTwo, lineDataThree, lineDataFour } from "lib/utils"
import axios from 'axios';
import { API_BASE_URL, TYPE_INFO,TYPE_FAIL, INTERVAL, TYPE_SUCCESS } from 'assets/constants/Constants';
import ShowToast from 'components/ShowToast';
import { LtePlusMobiledataTwoTone } from '@mui/icons-material';
import Plotly from 'plotly.js';
import Modal from 'react-modal';
import moment from "moment";
import * as Utils from "lib/utils";
import createPlotlyComponent from 'react-plotly.js/factory';

const Plot = createPlotlyComponent(Plotly);


class mainHomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      selectGraphGeneratedValue: "",
      siteOptions: [
        { label: "Daily", value: "daily" },
        { label: "Weekly", value: "weekly" },
        { label: "Monthly", value: "monthly" }],
      excelGenerateValue: "daily",
      flowMeterList: [],
      pressureMeterList: [],
      levelMeterList: [],
      metersActiveInactiveCount: [],
      modalIsOpen: false,
      selectedData: null,
      selectedLabel: '',
      startDate: moment().startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      flowGraphReadingsData: [],
      flowMeterActiveInactiveData: []
    }
  }
  async componentDidMount() {
    const metersActiveInactiveCount = await this.getMetersActiveInactiveCount();
    const flowMeterData = await this.getAllFlowMeters();
    const pressureMeterData = await this.getAllPressureMeters()
    const levelMeterData = await this.getAllLevelMeters()
    const flowGraphReadingsData = await this.getFlowMetersGraphDataList();
    

    const transformedFlowGraphDataActive = flowGraphReadingsData.data.result.map(item => ({
      x: item.date,
      y: item.readings ? item.readings.flow_meter_active_count : 0 
    }));
    
    const transformedFlowGraphDataInactive = flowGraphReadingsData.data.result.map(item => ({
      x: item.date,
      y: item.readings ? item.readings.flow_meter_inactive_count : 0 
    }));

  
    const lineDataForFlow = [{
      x: transformedFlowGraphDataActive.map(d => d.x), 
      y: transformedFlowGraphDataActive.map(d => d.y),
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: '#1f77b4' },
      line: { color: '#1f77b4' },
      borderRadius: '45%',
      name: "Active"
    },
    {
      x: transformedFlowGraphDataActive.map(d => d.x), // Extract x values
      y: transformedFlowGraphDataInactive.map(d => d.y), // Extract y values
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: 'red' },
      line: { color: 'red' },
      borderRadius: '45%',
      name: "Inactive"
    },
    {
      x: transformedFlowGraphDataActive.map(d => d.x),
      y: [0,0,0,0,0,0,0,0],
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: 'orange' },
      line: { color: 'orange' },
      borderRadius: '45%',
      name: "Faulty"
    }];
    this.setState({
      flowMeterList: flowMeterData.data.result,
      pressureMeterList: pressureMeterData.data.result,
      levelMeterList: levelMeterData.data.result,
      metersActiveInactiveCount: metersActiveInactiveCount.data.result[0],
      flowGraphReadingsData: flowGraphReadingsData.data.redings,
      flowMeterActiveInactiveData: lineDataForFlow
    });
    clearInterval(this.toggleClassInterval);
    clearInterval(this.readingInterval);
    this.toggleClassInterval = null;
    this.readingInterval = null;
  }
  getAllFlowMeters = async () => {
    return await axios(`${API_BASE_URL}/flow/meter/${sessionStorage.getItem("siteId")}`);
  }
  getAllPressureMeters = async () => {
    return await axios(`${API_BASE_URL}/pressure/meter/${sessionStorage.getItem("siteId")}`);
  }
  getAllLevelMeters = async () => {
    return await axios(`${API_BASE_URL}/level/meter/${sessionStorage.getItem("siteId")}`);
  }

  getMetersActiveInactiveCount = async () => {
    return await axios(`${API_BASE_URL}/meter/activeInactive/counts/${sessionStorage.getItem("siteId")}`);
  }

  getFlowMetersGraphDataList = async () =>{
    return await axios(`${API_BASE_URL}/flow/graph/metersactiveinactivecount/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.excelGenerateValue}`)
  }

  flowMeterPageRender = (flowMeterList) => {
    if (flowMeterList.length > 0) {
      this.props.history.push("/flow-meter-page");
    }
    else {
      ShowToast("Flow meters not found", TYPE_INFO, INTERVAL, this.props, "");
    }
  }
  pressureMeterPageRender = (pressureMeterList) => {
    if (pressureMeterList.length > 0) {
      this.props.history.push("/pressure-meter-page");
    }
    else {
      ShowToast("Pressure meters not found", TYPE_INFO, INTERVAL, this.props, "");
    }
  }

  levelMeterPageRender = (levelMeterList) => {
    if (levelMeterList.length > 0) {
      this.props.history.push("/level-meter-page");
    }
    else {
      ShowToast("Level meters not found", TYPE_INFO, INTERVAL, this.props, "");
    }
  }

  flowMeterReadingsPageRender = () => {
    this.props.history.push("/flow-readings-page");
  }

  pressureMeterReadingsPageRender = () => {
    this.props.history.push("/pressure-readings-page");
  }

  levelMeterReadingsPageRender = () => {
    this.props.history.push("/level-readings-page");
  }

  handleGraphClick = (data,label) => {
    this.setState({ selectedData: data, modalIsOpen: true, selectedLabel: label });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false, selectedData: null,selectedLabel: '' });
  };

  ChangeHandler =( event) => {
    this.setState({ selectGraphGeneratedValue: { label: event.label, value: event.value }, })
  }

  getFlowReportReadings = async () => {
    // if (this.isFormExcelValid()) {
        try {
           
            let interval = 100000;

            if (this.toggleClassInterval && this.toggleClassInterval) {
              Utils.showLoading("Fetching all flow meters graph data...");
                clearInterval(this.toggleClassInterval);
                clearInterval(this.readingInterval);
                this.toggleClassInterval = null;
                this.readingInterval = null;
            }
            else {
                interval = 0;
            }

            setTimeout(async () => {
                Utils.showLoading("Fetching all flow meters graph data...");
                const readingsFlowGraphData = await axios(`${API_BASE_URL}/flow/graph/metersactiveinactivecount/readings/${sessionStorage.getItem('siteId')}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}/${this.state.selectGraphGeneratedValue.value}`);
                Utils.hideLoading();
                if (readingsFlowGraphData.data.result.length > 0) {
                    ShowToast('Report created successfully', TYPE_SUCCESS, INTERVAL, null, "");
                  
                    const transformedFlowGraphDataActive = readingsFlowGraphData.data.result.map(item => ({
                      x: item.date,
                      y: item.readings ? item.readings.flow_meter_active_count : 0 
                    }));
                    
                    const transformedFlowGraphDataInactive = readingsFlowGraphData.data.result.map(item => ({
                      x: item.date,
                      y: item.readings ? item.readings.flow_meter_inactive_count : 0 
                    }));
                
                  
                    const lineDataForFlow = [{
                      x: transformedFlowGraphDataActive.map(d => d.x), 
                      y: transformedFlowGraphDataActive.map(d => d.y),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: { color: '#1f77b4' },
                      line: { color: '#1f77b4' },
                      borderRadius: '45%',
                      name: "Active"
                    },
                    {
                      x: transformedFlowGraphDataActive.map(d => d.x),
                      y: transformedFlowGraphDataInactive.map(d => d.y), 
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: { color: 'red' },
                      line: { color: 'red' },
                      borderRadius: '45%',
                      name: "Inactive"
                    },
                    {
                      x: transformedFlowGraphDataActive.map(d => d.x),
                      y: [0,0,0,0,0,0,0,0],
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: { color: 'orange' },
                      line: { color: 'orange' },
                      borderRadius: '45%',
                      name: "Faulty"
                    }];
                    this.setState({
                      flowMeterActiveInactiveData: lineDataForFlow
                    }, () => {
                        console.log("flowExcelReadings::", this.state.flowMeterActiveInactiveData)
                    });
                }
                else {
                    ShowToast('There is no data to fetch!', TYPE_SUCCESS, INTERVAL, null, "");
                }
            }, interval);
        } catch (error) {
            ShowToast('Something went wrong.', TYPE_FAIL, INTERVAL, null, "");
            Utils.hideLoading();
        }
    // }
}

  render() {
    const { metersActiveInactiveCount } = this.state
    const { modalIsOpen, selectedData,selectedLabel,flowMeterActiveInactiveData } = this.state;
    console.log("metersActiveInactiveCount", metersActiveInactiveCount)
    const FlowactiveCount = metersActiveInactiveCount.flow_meter_active_count || 0;
    const FlowinactiveCount = metersActiveInactiveCount.flow_meter_inactive_count || 0;
    const FlowfaultyCount = metersActiveInactiveCount.flow_meter_fault_count || 0;
    const FlowpieData = [
      {
        values: [FlowactiveCount, FlowinactiveCount, FlowfaultyCount],
        labels: ['Active', 'Inactive', 'Faulty'],
        type: 'pie',
        textinfo: 'label+value',
        hoverinfo: 'label+value',
        textposition: 'inside',
        marker: {
          colors: ['#DFF1FF', '#FEE7DA', '#FEF7D9'],
          line: {
            color: '#aaa',
            width: 1,
          },
        },
      },
    ];
    const PressurepieData = [
      {
        values: [0, 0, 0],
        labels: ['Active', 'Inactive', 'Faulty'],
        type: 'pie',
        textinfo: 'label+value',
        hoverinfo: 'label+value',
        textposition: 'inside',
        marker: {
          colors: ['#DFF1FF', '#FEE7DA', '#FEF7D9'],
          line: {
            color: '#aaa',
            width: 1,
          },
        },
      },
    ];
    const LevelpieData = [
      {
        values: [0, 0, 0],
        labels: ['Active', 'Inactive', 'Faulty'],
        type: 'pie',
        textinfo: 'label+value',
        hoverinfo: 'label+value',
        textposition: 'inside',
        marker: {
          colors: ['#DFF1FF', '#FEE7DA', '#FEF7D9'],
          line: {
            color: '#aaa',
            width: 1,
          },
        },
      },
    ];
    const TemperaturepieData = [
      {
        values: [0, 0, 0],
        labels: ['Active', 'Inactive', 'Faulty'],
        type: 'pie',
        textinfo: 'label+value',
        hoverinfo: 'label+value',
        textposition: 'inside',
        marker: {
          colors: ['#DFF1FF', '#FEE7DA', '#FEF7D9'],
          line: {
            color: '#aaa',
            width: 1,
          },
        },
      },
    ];

    const layout = {
      height: 200,
      width: 230,
      margin: { t: 2, b: 2, l: 0, r: 0 },
      showlegend: true,
      paper_bgcolor: 'transparent',
    };
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        alignItems: "center",
        justifyContent: "center",
        transform: 'translate(-50%, -50%)',
        width: '50%', 
        height: '60%', 
        overflow: 'auto', 
      },
    };
  
    return (
      <div>
        <div style={{ paddingLeft: "10%", display: "flex", justifyContent: "space-between", paddingRight: "10%", paddingBottom: "0%", paddingTop: "2%" }}>
          <Card sx={{ maxWidth: 230 }} style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.4)", backgroundColor: "rgb(255, 248, 248)" }}>
            <CardMedia
              component="img"
              alt="green iguana"
              style={{ backgroundColor: "#DFF1FF", width: "100%", padding: "50px" }}
              image="/images/flow-meter.png"
            />
            <CardContent>
              <Typography style={{ fontSize: "25px", fontWeight: "bold", color: "black", paddingBottom: "10px" }}>
                Flow
        </Typography>
              <Typography style={{ fontSize: "14px", fontWeight: "100", color: "GrayText" }}>
                A flow water meter is a device used to measure the volume or flow rate of water passing through a specific point in a plumbing system
        </Typography>
            </CardContent>
            <CardActions>
              <Button onClick={() => this.flowMeterPageRender(this.state.flowMeterList)} style={{ fontSize: "14px", fontWeight: "bold" }} size="large">METERS</Button>
              <Button onClick={() => this.flowMeterReadingsPageRender()} style={{ fontSize: "14px", fontWeight: "bold" }} size="large">READINGS</Button>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 230 }} style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)", backgroundColor: "#FEF7D9" }}>
            <CardMedia
              component="img"
              alt="green iguana"
              style={{ backgroundColor: "#DFF1FF", width: "100%", padding: "50px" }}
              image="/images/pressure-meter.png"
            />
            <CardContent>
              <Typography style={{ fontSize: "25px", fontWeight: "bold", color: "black", paddingBottom: "10px" }}>
                Pressure
        </Typography>
              <Typography style={{ fontSize: "14px", fontWeight: "100", color: "GrayText" }}>
                A pressure water meter is an instrument designed to measure the pressure of a fluid at a specific location within a piping system
        </Typography>
            </CardContent>
            <CardActions>
              <Button onClick={() => this.pressureMeterPageRender(this.state.pressureMeterList)} style={{ fontSize: "14px", fontWeight: "bold" }} size="large">METERS</Button>
              <Button onClick={() => this.pressureMeterReadingsPageRender()} style={{ fontSize: "14px", fontWeight: "bold" }} size="large">READINGS</Button>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 230 }} style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)", backgroundColor: "rgb(246,253,246" }}>
            <CardMedia
              component="img"
              alt="green iguana"
              style={{ backgroundColor: "#DFF1FF", width: "100%", padding: "50px" }}
              image="/images/level-meter.png"
            />
            <CardContent>
              <Typography style={{ fontSize: "25px", fontWeight: "bold", color: "black", paddingBottom: "10px" }}>
                Level
        </Typography>
              <Typography style={{ fontSize: "14px", fontWeight: "200", color: "GrayText" }}>
                A level water meter used to measure the height or level of liquid within a specific container in a storage system.
        </Typography>
            </CardContent>
            <CardActions style={{ paddingTop: "30px" }}>
              <Button onClick={() => this.levelMeterPageRender(this.state.levelMeterList)} style={{ fontSize: "14px", fontWeight: "bold" }} size="large">METERS</Button>
              <Button onClick={() => this.levelMeterReadingsPageRender()} style={{ fontSize: "14px", fontWeight: "bold" }} size="large">READINGS</Button>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 230 }} style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)", backgroundColor: "rgb(250, 250, 250)" }}>
            <CardMedia
              component="img"
              alt="green iguana"
              style={{ backgroundColor: "#DFF1FF", width: "100%", padding: "50px" }}
              image="/images/temprature-meter.png"
            />
            <CardContent>
              <Typography style={{ fontSize: "25px", fontWeight: "bold", color: "black", paddingBottom: "10px", paddingRight: "25px" }}>
                Temperature
        </Typography>
              <Typography style={{ fontSize: "14px", fontWeight: "100", color: "GrayText" }}>
                A temperature water meter used to measure the heat or temperature of a substance at a specific point in a heating system
        </Typography>
            </CardContent>
            <CardActions>
              <Button style={{ fontSize: "14px", fontWeight: "bold" }} size="large">METERS</Button>
              <Button style={{ fontSize: "14px", fontWeight: "bold" }} size="large">READINGS</Button>
            </CardActions>
          </Card>
        </div>
        <div style={{ height: "50px", backgroundColor: "lightgrey", fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center", margin: "25px auto", width: "100%", fontWeight: "500" }}><span>ACTIVE METERS </span></div>
        <div>
          <div style={{ marginLeft: "140px", display: "flex", justifyContent: "space-between", marginRight: "100PX", paddingBottom: "70vm", paddingTop: "70vm" }}>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Flow</div>
              <div className="small-chart-content">
                <div className="plotly-small-chart">
                  <Plot data={FlowpieData} layout={layout} />
                </div>
              </div>
            </div>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Pressure</div>
              <div className="small-chart-content">
                {/* <div className="plotly-small-chart">
                  <Plot data={PressurepieData} layout={layout} />
                </div> */}
                  <div style={{justifyContent:"center", alignItems:"center", marginTop:"90px"}}>NO DATA</div>
              </div>
            </div>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Level</div>
              <div className="small-chart-content">
                {/* <div className="plotly-small-chart">
                  <Plot data={LevelpieData} layout={layout} />
                </div> */}
                  <div style={{justifyContent:"center", alignItems:"center", marginTop:"90px"}}>NO DATA</div>
              </div>
            </div>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Temperature</div>
              <div className="small-chart-content">
                {/* <div className="plotly-small-chart">
                  <Plot data={TemperaturepieData} layout={layout} />
                </div> */}
                  <div style={{justifyContent:"center", alignItems:"center", marginTop:"90px"}}>NO DATA</div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "50px", backgroundColor: "lightgrey", fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center", margin: "25px auto", width: "100%", fontWeight: "500" }}><span>METER READINGS TRENDING GRAPH</span></div>
        {/* <div style={{ paddingLeft: "5%", display: "flex", justifyContent: "space-between", paddingRight: "10%", paddingBottom: "5%", paddingTop: "1%" }}> */}
          <div className="report-container ">
            <div className="addUser-one">
              <Select
                className="site-select-dropdown"
                style={{ color: "black" }}
                value={this.state.selectGraphGeneratedValue}
                placeholder="Select graph view"
                onChange={this.ChangeHandler}
                options={this.state.siteOptions}
              />
            </div>
            <div className="report-button">
              <button id="button" className="brand-button-home-page report-button" onClick={this.getFlowReportReadings} >Get Graph</button>
            </div>
          </div>
          <div style={{ paddingLeft: "10%", display: "flex", justifyContent: "space-between", paddingRight: "10%", paddingBottom: "2%", paddingTop: "4%" }}>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Flow</div>
              <div className="small-chart-content">
                <div className="plotly-small-chart">
                  <div onClick={() => this.handleGraphClick(flowMeterActiveInactiveData, "FLOW")}>
                    <LineChartAllMeters data={flowMeterActiveInactiveData} />
                  </div>
                </div>
              </div>
            </div>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Pressure</div>
              <div className="small-chart-content">
                <div className="plotly-small-chart">
                  {/* <div onClick={() => this.handleGraphClick(lineDataTwo,"PRESSURE")}>
                    <LineChartAllMeters data={lineDataTwo} />
                  </div> */}
                   <div style={{justifyContent:"center", alignItems:"center", marginTop:"100px"}}>NO DATA</div>
                </div>
              </div>
            </div>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Level</div>
              <div className="small-chart-content">
                <div className="plotly-small-chart">
                  {/* <div onClick={() => this.handleGraphClick(lineDataThree,"LEVEL")}>
                    <LineChartAllMeters data={lineDataThree} />
                  </div> */}
                   <div style={{justifyContent:"center", alignItems:"center", marginTop:"100px"}}>NO DATA</div>
                </div>
              </div>
            </div>
            <div className="small-chart-individual compliance-small-chart">
              <div className="small-chart-label">Temperature</div>
              <div className="small-chart-content">
                <div className="plotly-small-chart">
                  {/* <div onClick={() => this.handleGraphClick(lineDataFour,"TEMPERATURE")}>
                    <LineChartAllMeters data={lineDataFour} />
                  </div> */}
                  <div style={{justifyContent:"center", alignItems:"center", marginTop:"100px"}}>NO DATA</div>
                </div>
              </div>
            </div>
          </div>

          <Modal isOpen={modalIsOpen} onRequestClose={this.closeModal} style={customStyles} >
            <button onClick={this.closeModal} style={{ float: 'right' }}>X</button>
            {selectedData && (
              <div style={{ width: '100%', height: '100%' }}>
                <h4 style={{textAlign: "center", justifyContent: "center", alignItems:"center", color: 'blue'}}>{selectedLabel}</h4>
                <LineChartAllMetersBig data={selectedData} />
              </div>
            )}
          </Modal>
        {/* </div> */}
        <div style={{ height: "50px", backgroundColor: "lightgrey", fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center", margin: "25px auto", width: "100%", fontWeight: "500" }}><span>ALL METER MAP VIEW</span></div>
        <div style={{ paddingLeft: "10%", display: "flex", justifyContent: "space-between", paddingRight: "10%", paddingBottom: "2%", paddingTop: "0%" }}>
          <i style={{ paddingLeft: "50px", fontSize: "25px", fontFamily: "sans-serif" }} className={`${MAP_MARKER_ICON} map-marker-icon-flow  `}> Flow</i>
          <i style={{ paddingLeft: "10px", fontSize: "25px", fontFamily: "sans-serif" }} className={`${MAP_MARKER_ICON} map-marker-icon-pressure  `}> Pressure</i>
          <i style={{ paddingLeft: "10px", fontSize: "25px", fontFamily: "sans-serif", fontFamily: "sans-serif" }} className={`${MAP_MARKER_ICON} map-marker-icon-level  `}> Level</i>
          <i style={{ paddingLeft: "10px", fontSize: "25px", fontFamily: "sans-serif" }} className={`${MAP_MARKER_ICON} map-marker-icon-tempereture  `}> Temperature</i>
        </div>
        <div>
          <div style={{ height: "100%", paddingLeft: "150px", display: "flex", justifyContent: "space-between", paddingRight: "100PX", paddingBottom: "50px" }}>
            <WrappedMapAllMeters />
          </div>
        </div>
      </div>
    );
  }
}
export default mainHomePage;
