import React, { Component } from 'react'
import axios from 'axios';
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL, SAMPLE_FILE_URL, SUPER_ADMIN } from 'assets/constants/Constants';
import { EDIT_ICON } from 'assets/constants/Icons';
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';
import { hasPermission }  from "components/Permission";
import MaterialTable from "material-table";
import CSVFileReader from 'components/CSVFileReader';
import FileDownloader from 'components/FileDownloader';

export class SitesPage extends Component {
    constructor(props) {
        super(props)
      
        const userRoleDetails = sessionStorage.getItem("userRole");
        this.state = {
            isLoading: true,
            buttonText: "Add Site",
            siteList: [],
            userRoleDetails: userRoleDetails ? JSON.parse(userRoleDetails) : null,
            importList: []
        }
    }

    async componentDidMount() {
        const siteData = await this.getAllSites();
        this.setState({
            siteList: siteData.data.result,
            isLoading: false
        });
    }

    getAllSites = async () => {
        if(this.state.userRoleDetails.user_role === SUPER_ADMIN ){
            return await axios(`${API_BASE_URL}/site`);
        }
        else{
            return await axios(`${API_BASE_URL}/site/${sessionStorage.getItem("siteId")}`);
        }
    }

    addSiteHandler = (item) => {
        if(item){
            const rowItem = {
                _id: item.siteId,
                site_name: item.siteName,
                street: item.siteStreet,
                city: item.siteCity,
                pincode: item.sitePincode,
                backend_url:item.backendURL,
                defaultImage_uploadedImage: item.backGroundImage,
                background_image_selected_type: item.backGroundImageSelectType,
                site_inactive: item.siteInactive,
                site_todays_readings_check: item.siteTodaysReadingsCheck,
                client_logoImage_primary: item.clientLogoPrimary,
                client_logoImage_secondary: item.clientLogoSecondary,
                client_name: item.clientName,
                client_id: item.clientId
            }
            this.props.history.push({
                pathname: "/create-site-page",
                state: rowItem
            })
        }
        else{
            this.props.history.push({
                pathname: "/create-site-page",
                state: null
            })
        }
    }

    handleFile = (fileData) => {
        console.log("Hello there: ", JSON.stringify(fileData));
        if (fileData[0].errors.length === 0) {
            const importList = [...this.state.importList];
            for (let index = 1; index <= fileData.length - 1; index++) {
                if(fileData[index].data[0] && fileData[index].data[1] && fileData[index].data[2] && fileData[index].data[3]){
                    importList.push({
                        site_name: fileData[index].data[0],
                        street: fileData[index].data[1],
                        city: fileData[index].data[2],
                        pincode: fileData[index].data[3],
                        backend_url:fileData[index].data[4]
                    });
                }
            }
            
            this.setState({
                importList
            }) 
        }
        else {
            ShowToast('Something went wrong. Please try after sometime!', TYPE_FAIL, INTERVAL, this.props, ""); 
        
        }
    };

    importSiteHandler = async (event) => {
        let requestParameter= null;
        event.preventDefault();
        this.setState ({isLoading: true,});

        const sitePromise = this.state.importList.map(async(item, index) => {
            return new Promise( async (resolve) => {
                requestParameter = {
                    siteName : item.site_name,
                    siteStreet : item.street,
                    siteCity : item.city,
                    sitePincode : item.pincode,
                    backendURL:item.backend_url
                }
                const siteResponse = await axios.post(`${API_BASE_URL}/site`, requestParameter);
                resolve(siteResponse);
            })
        });
        
        const siteResponse = await Promise.all(sitePromise);
        if(siteResponse.length > 0 && siteResponse.length === this.state.importList.length){
            ShowToast('Site created successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
            const siteData = await this.getAllSites();
            this.setState({
                siteList: siteData.data.result,
                importList: [],
                isLoading: false
            });
        }
    }

    cancelHandler = (event) => {
        window.location.reload();
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            if(this.state.importList.length === 0){
                return (
                    <div className="main-content">
                        <div className="back">
                            <ul className="breadcrumb1">
                                <li>Site Master</li>
                            </ul>
                        </div>
                        <div className="list-wrapper">
                            <div className="site-button-div">
                            {
                                hasPermission("site", "create") && (
                                <>
                                    <button className="brand-button" onClick={ () => this.addSiteHandler(null) }>{ this.state.buttonText }</button>
                                    <CSVFileReader handleFile={this.handleFile} />
                                    <label className="csv-link-label" onClick={() =>{FileDownloader(SAMPLE_FILE_URL+ "sites.csv" , "sites.csv")}}>
                                        Sample Import CSV
                                    </label>
                                </>
                                )
                            }
                            </div>
                            <div  className="mt-4 border list-table-div site-table" style={{marginBottom:50}}>
                            {
                                (this.state.siteList.length > 0 || this.state.siteList!=null) && (
                                    <MaterialTable
                                        columns={[
                                            { title: "Site Name", field: "siteName", 
                                            cellStyle: {cellWidth: '20%', fontWeight:'bolder', color:'#000000', fontSize:14 }
                                            },
                                            { title: "Site Street", field: "siteStreet", 
                                            cellStyle: { cellWidth: '20%', color:'#808080', fontSize:14 },
                                            sorting:false 
                                            },
                                            { title: "Site City", field: "siteCity", 
                                            cellStyle: {cellWidth: '20%', color:'#808080', fontSize:14 },
                                            sorting: false
                                            },
                                            { title: "Site Pincode", field: "sitePincode", 
                                            cellStyle: {cellWidth: '20%', color:'#808080', fontSize:14 }
                                            },
                                        ]}
                                        data={
                                            this.state.userRoleDetails.user_role === SUPER_ADMIN ? (
                                                this.state.siteList.map((item, index) => {
                                                    return (
                                                        {
                                                            siteName: item.site_name,
                                                            siteStreet: item.street,
                                                            siteCity: item.city,
                                                            sitePincode: item.pincode,
                                                            siteId: item._id,
                                                            backendURL:item.backend_url,
                                                            backGroundImage: item.defaultImage_uploadedImage,
                                                            backGroundImageSelectType: item.background_image_selected_type,
                                                            siteInactive: item.site_inactive,
                                                            siteTodaysReadingsCheck: item.site_todays_readings_check,
                                                            clientLogoPrimary: item.client_logoImage_primary,
                                                            clientLogoSecondary: item.client_logoImage_secondary,
                                                            clientName: item.client_name,
                                                            clientId: item.client_id,
                                                        }
                                                    )
                                                })
                                            ) : (
                                                [{
                                                    siteName: this.state.siteList.site_name ,
                                                    siteStreet: this.state.siteList.street,
                                                    siteCity: this.state.siteList.city,
                                                    sitePincode: this.state.siteList.pincode,
                                                    siteId: this.state.siteList._id,
                                                    backendURL:this.state.backend_url,
                                                    backGroundImage: this.state.siteList.defaultImage_uploadedImage,
                                                    backGroundImageSelectType: this.state.siteList.background_image_selected_type,
                                                    siteInactive: this.state.siteList.site_inactive,
                                                    siteTodaysReadingsCheck: this.state.siteList.site_todays_readings_check,
                                                    clientLogoPrimary: this.state.siteList.client_logoImage_primary,
                                                    clientLogoSecondary: this.state.siteList.client_logoImage_secondary,
                                                    clientName: this.state.siteList.client_name,
                                                    clientId: this.state.siteList.client_id,
                                                }]
                                            )
                                        }

                                        actions={[
                                            {
                                                icon: () => <i className={hasPermission("site", "update") ? `${EDIT_ICON}` : "not-active"} title="Edit Site"></i>,
                                                onClick: (event, rowData) =>
                                                    hasPermission("site", "update") ? this.addSiteHandler(rowData) : ""
                                            }
                                        ]}
                                        options={{
                                            actionsColumnIndex: -1,
                                            pageSize:25,
                                            pageSizeOptions:[25,50,75,100],
                                            headerStyle: {
                                                color: '#999998',
                                                fontWeight: 'bolder',
                                                fontSize: 15
                                            },
                                            filtering: true,
                                            sorting: true,
                                            columnsButton: true,
                                            exportButton: true,
                                            exportAllData: true, 
                                            exportFileName: "Site Data",
                                        }}
                                    />
                                )
                            }
                            </div>
                        </div>
                    </div>
                )
            }
            else{
                return(
                    <>
                    <div className="site-button-div">
                    {
                        hasPermission("site", "create") && (
                        <>
                            <button className="brand-button" onClick={ () => this.addSiteHandler(null) }>{ this.state.buttonText }</button>
                            <CSVFileReader handleFile={this.handleFile} />
                        </>
                        )
                    }
                    </div>
                    
                    <div className="mt-4 border list-table-div site-table">
                        <MaterialTable
                            title = {<div className="csv-title">IMPORTED SITE DATA</div>}
                            columns={[
                                { title: "Site Name", field: "siteName", 
                                cellStyle: {cellWidth: '20%', fontWeight:'bolder', color:'#000000', fontSize:14 }
                                },
                                { title: "Site Street", field: "siteStreet", 
                                cellStyle: { cellWidth: '20%', color:'#808080', fontSize:14 },
                                },
                                { title: "Site City", field: "siteCity", 
                                cellStyle: {cellWidth: '20%', color:'#808080', fontSize:14 },
                                },
                                { title: "Site Pincode", field: "sitePincode", 
                                cellStyle: {cellWidth: '20%', color:'#808080', fontSize:14 }
                                },
                            ]}
                            data={
                                    this.state.importList.map((item, index) => {
                                    return (
                                        {
                                            siteName: item.site_name,
                                            siteStreet: item.street,
                                            siteCity: item.city,
                                            sitePincode: item.pincode,
                                            backendURL:item.backend_url
                                        }
                                    )
                                })
                            }
                            options={{
                                headerStyle: {
                                    color: '#999998',
                                    fontWeight: 'bolder',
                                    fontSize: 15
                                },
                            }}
                        />
                    </div>
                    <div className="site-button-div" style={{marginTop: 30, marginBottom:50, marginLeft:17}}>
                        <button className="brand-button" onClick={ this.importSiteHandler }>Submit</button>
                        <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                    </div>
                    </>
                )
            }
        }
    }
}

export default SitesPage