import React, { useState , useContext, useEffect} from 'react'
import AuthContext from 'context/AuthProvider';
import { USER_ICON, MAP_MARKER_ICON } from "assets/constants/Icons";
import Select from 'react-select';
import { SUPER_ADMIN } from 'assets/constants/Constants';

function NavigationBar(props) {
    const addEditUserHandler = (userDetail) => {
        props.history.push({
            pathname: "/create-user-page",
            state: {...userDetail, isFromProfile: true}
        });
    }

    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedLogo , setSelectedLogo] = useState(null);
    const [selectedLogoSecondary , setSelectedLogoSecondary] = useState(null);
    const [selectedClientName, setSelectedClientName ] = useState(null);
    const [siteOptions, setSiteOption] = useState([]);
    const [brandLogo, setBrandLogo] = useState("");
    const [brandLogoSecondary, setBrandLogoSecondary] = useState(null);

    const authContext = useContext(AuthContext);
    console.log(authContext.isAuthenticated());
    const userDetail = JSON.parse(sessionStorage.getItem("userData"));
    
    const userRole = JSON.parse(sessionStorage.getItem("userRole"));

    useEffect(() => {
        const renderSiteOptions = () => {
            let selectedSite=null;
            let siteOptions = [];
            if(sessionStorage.getItem("siteData") !== null){
                const siteDetail = JSON.parse(sessionStorage.getItem("siteData"));
                console.log("siteDetail::",siteDetail)
                if(siteDetail.length > 0) {
                    siteDetail.forEach((siteItem) => {
                        if(sessionStorage.getItem("siteId") === siteItem._id){
                            console.log("siteItem::",siteItem)
                            selectedSite = {value: siteItem._id, label: siteItem.site_name }
                            setSelectedLogo ({clientLogo1: siteItem.client_logoImage_primary ? siteItem.client_logoImage_primary  : null })
                            setSelectedLogoSecondary ({clientLogo2: siteItem.client_logoImage_secondary ? siteItem.client_logoImage_secondary : null })
                            console.log("siteItem.client_name:",siteItem.client_name)
                            sessionStorage.setItem("clientName",siteItem.client_name)
                        }
                        siteOptions.push({ value: siteItem._id, label: siteItem.site_name })
                    });
                    setSelectedSite(selectedSite);
                    setSiteOption(siteOptions);
                }
            }
        }

        renderSiteOptions();
    }, []);

    useEffect(() => {
        const setBrandLogoFun = () => {
            setBrandLogo(authContext.BRAND_LOGO);
        }

        setBrandLogoFun();
    }, [authContext.BRAND_LOGO])

    useEffect(() => {
       console.log("selectedLogo::",selectedLogo)
       if(selectedLogo && selectedLogo.clientLogo1 !== null){
        setBrandLogo(selectedLogo.clientLogo1.base64Data)
       }
       else{
        setBrandLogo(authContext.BRAND_LOGO);
       }
       if(selectedLogoSecondary && selectedLogoSecondary.clientLogo2 !== null){
           setBrandLogoSecondary(selectedLogoSecondary.clientLogo2.base64Data)
       }
       else{
        setBrandLogoSecondary(null)
       }
    }, [selectedLogo,authContext.BRAND_LOGO,selectedLogoSecondary])

    const siteChangeHandler = (event) => {
        setSelectedSite(event);
        sessionStorage.setItem("siteId", event.value);
        sessionStorage.setItem("siteName", event.label);
        if (window.confirm("Are you sure to change site as " + event.label + ", if you change it then all " + event.label + " site data will display instead of current site") === true) {
            console.log("window.location.pathname::", window.location.pathname);
            if (
                window.location.pathname === "/route-meter-mapping" ||
                window.location.pathname === "/map-view" ||
                window.location.pathname === "/create-route-page" ||
                window.location.pathname === "/create-meter-page" ||
                window.location.pathname === "/create-user-page" ||
                window.location.pathname === "/meter-view-page" ||
                window.location.pathname === "/create-customer-page" ||
                window.location.pathname === "/customer-view-page" ||
                window.location.pathname === "/route-meter-mapping"
            ) {
                window.history.back();
            } else if (window.location.pathname === "/meter-reading") {
                window.location.href = "/master-route-page";
            } else {
                window.location.reload();
            }
        }
    }
    return (
        <nav className="top-navigation-bar">
            {
                selectedLogo && selectedLogo.clientLogo1 === null ? (
                    <div className="logo-container">
                        <a href="/home-page">
                            <img alt="Brand Logo" className="img-no-padding img-responsive" src={brandLogo} />
                        </a>
                    </div>
                ):(
            <div className="logo-container-one">
                <a href="/home-page">
                    <img alt="Brand Logo" className="img-no-padding img-responsive" src={brandLogo} />
                </a>
            </div>
            )
        }
            <div className="site-client-name">
                {sessionStorage.getItem("clientName")
                    ? sessionStorage.getItem("clientName").toUpperCase()
                    : ""}
            </div>


            <div className="site-select-dropdown-and-user-info-container">
                <div className="site-select-dropdown-container">
                    <div className="top-navigation-bar-icon-container">
                        <i className={ `${MAP_MARKER_ICON} top-navigation-bar-icon` } aria-hidden="true"></i>
                    </div>

                    { (userRole.user_role === SUPER_ADMIN) ? (
                        (sessionStorage.getItem("siteData") !== null) && (sessionStorage.getItem("userRole") !== null) && (
                            <Select className="site-select-dropdown"
                                value={selectedSite}
                                onChange={siteChangeHandler}
                                options={siteOptions}
                            />
                        )
                    ):(
                        (sessionStorage.getItem("siteName")) && (
                            <label>{sessionStorage.getItem("siteName")} </label>
                        )
                    )}
                </div>

                { (sessionStorage.getItem("userData") !== null) && (
                    <div className="user-info-container">
                        <label>{ `Hi, ${userDetail.first_name} (${userRole.user_role})` }</label>
                        <div className="top-navigation-bar-icon-container" onClick={ () => addEditUserHandler(userDetail) } title="Edit user information">
                            <i className={ `${USER_ICON} top-navigation-bar-icon` } ></i>
                        </div>
                    </div>
                )}
            </div>
            {
                console.log("brandLogoSecondary::",brandLogoSecondary),
                brandLogoSecondary  !== null && (
                    <div className="logo-container-two">
                        <a href="/home-page">
                            <img alt="Brand Logo" className="img-no-padding img-responsive" src={brandLogoSecondary} />
                        </a>
                    </div>
                )
            }
        </nav>
    );
}

export default NavigationBar;

