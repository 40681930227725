import React, { Component } from 'react'
import axios from 'axios';
import { API_BASE_URL,  TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from 'assets/constants/Constants';
import { EDIT_ICON, TRASH_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import { hasPermission }  from "components/Permission";
import ShowToast from 'components/ShowToast';
import MaterialTable from "material-table";


export class ZoneAndSubZonePage extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
            isLoading: true,
            importList: 0,
            zoneList: null,
            buttonText: "Add Zone",
            siteId: sessionStorage.getItem("siteId"),
            siteName: sessionStorage.getItem("siteName"),
        }
    }

    async componentDidMount() {
        const zoneList = await this.getZoneList();
        const meterList = await this.getAllMeters();
        this.setState({
            isLoading: false,
            zoneList: zoneList.data.result ? zoneList.data.result : null ,
            meterList: meterList.data.result
        });
    }

        
    getZoneList = async () => {
        return await axios.get(`${API_BASE_URL}/zone/subzone/${this.state.siteId}`);
    }

    getAllMeters = async () => {
        return await axios(`${API_BASE_URL}/meter/site/${sessionStorage.getItem("siteId")}?limit=${this.state.pageSize}&page=${this.state.currentPage}`);
    }

  


    addEditUserHandler = (item) => {
        if(item){
            const rowItem = {
                _id:item.zoneAndSubZoneId,
                zone:item.zone,
                subZone:item.subZone,
            }
            this.props.history.push({
                pathname: "/create-zone-subzone-page",
                state: rowItem
            });
        }
        else{
            this.props.history.push({
                pathname: "/create-zone-subzone-page",
                state: null
            });
        }
    }

    SubzoneRenderPageHandler = (item) => {
        this.props.history.push("/subzone-page");
    }

    


    handleDelete = async (item) => {
        if (window.confirm("Are you sure you want to delete this zone and subzone?")) {
            this.setState({isLoading: true});

            const meterList = this.state.meterList

            const isZoneAssignedToMeter = meterList.some((meter) => meter.zone === item.zone);
            const Meter = meterList.filter(meter => meter.zone === item.zone).map(meter => meter.serial_number); 

            if (isZoneAssignedToMeter) {
                ShowToast(`This zone is assigned to this ${Meter} meter`, TYPE_FAIL, INTERVAL, this.props, "");
                this.setState({ isLoading: false });
                return;
            }


            let deleteResponse = await axios.delete(`${ API_BASE_URL }/zone/subzone/${item.zoneAndSubZoneId}`);
            if(deleteResponse.status === 200){
                const zoneList = await this.getZoneList();
                this.setState({
                    zoneList: zoneList.data.result,
                    isLoading: false
                });
                ShowToast('Zone and sub zone deleted successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
            }
            else{
                ShowToast('Something went wrong. Please delete again after sometime!', TYPE_FAIL, INTERVAL, this.props, ""); 
            }
        }
    }


    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            if(this.state.zoneList.length >= 0){
                return (
                    <div className="main-content">
                        <div className="list-wrapper">
                            <div className="back">
                                <ul className="breadcrumb1">
                                    <li>Zone-Subzone Master</li>
                                </ul>
                            </div>
                            <div>
                                <div className="meter-button-div">
                                {
                                    hasPermission("meter", "create") && (
                                        <>
                                        <button className="brand-button" onClick={() => this.state.selectedItem === null ? this.addEditUserHandler(null) : this.addEditUserHandler(this.state.selectedItem)}>{ this.state.buttonText }</button>
                                        <button style={{marginLeft:"10px"}}className="brand-button" onClick={() =>this.SubzoneRenderPageHandler() }>ADD SUBZONE</button>
                                        </>
                                    )
                                }
                                </div>
                                <div className="mt-4 border list-table-div" >
                                {
                                    hasPermission("meter", "read") ? (
                                    this.state.zoneList.length > 0 ? (
                                        <MaterialTable
                                            columns={[
                                                { title: "Zone", field: "zone", 
                                                width: null, cellStyle: { width: "25%", fontWeight:'bolder', color:'#000000', fontSize:14 },
                                                },
                                                {
                                                    title: "Subzone",
                                                    field: "subZone",
                                                    width: null,
                                                    render: rowData => (
                                                        <div>
                                                            {rowData.subZone.length > 0 ?
                                                                rowData.subZone.map((subZoneItem, index) => (
                                                                    <div key={index}>
                                                                        {subZoneItem === "" ? "None" :rowData.subZone.length > 1 ? `${index + 1} - ${subZoneItem}` : `${subZoneItem}`}
                                                                    </div>
                                                                )) : "None"}
                                                        </div>
                                                    ),
                                                    cellStyle: { width: "25%", fontWeight: 'bolder', color: '#999998', fontSize: 14 },
                                                },
                                                { title: "Total Meters", field: "totalMeters", 
                                                width: null,backgroundColor:'green', cellStyle: { width: "50%", fontWeight:'bolder', color:'#000000',  fontSize:14 },
                                                }
                                                
                                            ]}
                                            data={this.state.zoneList.map((zone) => {
                                                const matchingMeters = this.state.meterList.filter(meter => meter.zone === zone.zone_name);
                                               
                                                return {
                                                    zoneAndSubZoneId: zone._id,
                                                    zone: zone.zone_name,
                                                    subZone: zone.sub_zone_name, 
                                                    totalMeters: matchingMeters.length 
                                                };
                                            })}
                                        
                                            //onRowClick={(event, rowData) => this.showMeterReadingHandler(rowData)}
                                            actions={[
                                                {
                                                    icon: () => <i className={hasPermission("meter", "update") ? `${EDIT_ICON}` : "not-active"} title="Edit Meter"></i> ,
                                                    onClick: (event, rowData) =>
                                                        hasPermission("meter", "update") ? this.addEditUserHandler(rowData) : ""
                                                },
                                                {
                                                    icon: () => <i className={hasPermission("meter", "delete") ? `${TRASH_ICON}` : "not-active"} title="Delete meter"></i>,
                                                    onClick: (event, rowData) =>
                                                        hasPermission("meter", "delete") ? this.handleDelete(rowData) : ""
                                                }
                                            ]}
                                            options={{
                                                actionsColumnIndex: -1,
                                                pageSize:10,
                                                pageSizeOptions:[15,20,25,30],
                                                headerStyle: {
                                                    color: '#999998',
                                                    fontWeight: 'bolder',
                                                    fontSize: 15
                                                },
                                                filtering: true,
                                                sorting: true,
                                                columnsButton: true,
                                                exportButton: true,
                                                exportAllData: true, 
                                                exportFileName: "zone and subZone Data"
                                            }}
                                        />
                                    ):(
                                        <div className="text-center pt-2">
                                            <label>Zone and subzone records not found!</label>
                                        </div>
                                    )
                                    ):(
                                        <div className="text-center pt-2">
                                            <label>You dont have permission to see list</label>
                                        </div>
                                    )
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default ZoneAndSubZonePage
