export const USER_ICON = "fa fa-user icon";
export const METER_ICON = "fa fa-tachometer icon";
export const ROUTE_ICON = "fa fa-road icon";
export const WIFI_1_ICON = "fa fa-wifi-1 icon";
export const WIFI_2_ICON = "fa fa-wifi-2 icon";
export const WIFI_ICON = "fa fa-wifi icon";
export const REFRESH_ICON = "fa fa-refresh icon";
export const SITE_ICON = "fa fa-sitemap icon";
export const CUSTOMER_ICON = "fa fa-user icon";
export const SORT_DOWN = "fa fa-caret-down icon";
export const SORT_UP = "fa fa-caret-up icon";
export const EDIT_ICON = "fa fa-edit icon";
export const TRASH_ICON = "fa fa-trash text-danger icon";
export const EYE_ICON = "fa fa-eye icon";
export const BOLT_ICON = "fa fa-bolt icon";
export const USER_PLUS_ICON = "fa fa-user-plus icon";
export const UNLINK_ICON = "fa fa-unlink icon";
export const CALENDAR_ICON = "fa-calendar icon";
export const MAP_MARKER_ICON = "fa fa-map-marker icon";
export const USERS_ICON = "fa fa-users icon";
export const VCARD_ICON = "fa fa-vcard-o icon";
export const LINE_CHART_ICON = "fa fa-line-chart icon";
export const SIGN_OUT_ICON = "fa fa-sign-out icon";
export const BAR_ICON = "fa fa-bars icon";
export const TIMES_CIRCLE_ICON = "fa fa-times-circle";
export const BACK_ARROW_ICON = "fa-angle-left";
export const CHECK_ICON = "fa fa-check icon";
export const CLOSE_ICON = "fa fa-close icon";
export const LOCK_ICON = "fa fa-lock ";
export const SAFARI_ICON = "fa fa-safari icon";
export const BILLING_ICON = "fa fa-credit-card icon";
export const SEARCH_ICON = "fa fa-search icon";
export const LINK_ICON = "fa fa-link icon";
export const FILE_ICON = "fa fa-file-text-o";
export const COPY_ICON = "fa fa-clipboard";
export const HISTORY_ICON = "fa fa-history";
export const GLOBE_ICON = "fa fa-globe icon";
export const CAMERA_ICON ="fa fa-camera icon";
export const CLEAR_ICON = "fa fa-trash"
export const CLICK_ICON = "fa fa-hand-pointer-o"
export const RIGHTMARK_ICON = "fa-solid fa-check"
export const CONFIG_ICON = "fa fa-cogs icon"
export const ZONE_SUBZONE_ICON = "fa fa-globe icon"
export const EXCEL_DWN_ICON = "fa fa-file-excel-o icon"
export const GRAPH_ICON = "fa fa-line-chart icon"
