import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL, TYPE_SUCCESS, INTERVAL } from "assets/constants/Constants";
import { TRASH_ICON, EYE_ICON} from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';
import { hasPermission }  from "components/Permission";
import Select from 'react-select';
import { defaultFormatDate, validateBitStatusAlarms  } from "lib/utils"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export class RouteMeterMappingPage extends Component {
    constructor(props) {
        super(props)
        const meterData = this.props.history.location.state;
        const flagVisible = this.props.history.location.state.isVisibleAddMeter ? this.props.history.location.state.isVisibleAddMeter : false;
        this.state = {
            isLoading: true,
            siteId: meterData === null ? null : meterData.rowItem.site_id,
            routeId: meterData === null ? null : meterData.rowItem._id,
            isVisibleAddMeter:flagVisible,
            routeMeterInfo: this.props.history.location.state,
            meterData,
            siteList: [],
            meterSiteData: {},
            meterList: [],
            meterDetail: [],
            error: {},
            selectedMeter: null,
            meterOptions: [],
        }
    }

    async componentDidMount() {
        const siteData = await this.getSiteList();
        const meterData = await this.getMeterList();
        const meterDetails = await this.getMeterDetail();
        const meterSite = siteData.data.result.filter((filterItem) => filterItem._id === this.state.meterData.rowItem.site_id)[0];
        this.setState({
            siteList: siteData.data.result,
            meterList: meterData.data.result,
            meterDetail: meterDetails.data.result,
            meterSiteData: meterSite ? meterSite : {},
            isLoading: false
        }, () => {
            this.renderMeterOptions(this.state.meterList);
        });
    }

    async refreshMeterList(){
        const meterData = await this.getMeterList();
        this.setState({
            meterList: meterData.data.result,
        }, () => {
            this.renderMeterOptions(this.state.meterList);
        });
        
    }
    
    getMeterList = async () => {
        return await axios(`${API_BASE_URL}/route_meter_map/meter/notinroute/${sessionStorage.getItem("siteId")}`);
    }

    getSiteList = async () => {
        return await axios(`${API_BASE_URL}/site`);
    }

    getMeterDetail = async () => {
        return await axios(`${API_BASE_URL}/route_meter_map/route/${this.state.meterData.rowItem._id}`);
    }
    
    createMeterHandler = async (event) => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({isLoading: true,});
            const getMeterDetails = this.state.meterList.find((meterItem) => meterItem._id === this.state.meterId);
            let serailNumber = "";
            if(getMeterDetails){
                serailNumber = getMeterDetails.serial_number;
            }

            const userDetails = JSON.parse(sessionStorage.getItem("userData"));
            const userFirstName = userDetails.first_name;
            const userLastName = userDetails.last_name; 
            const userEmailId = userDetails.email_id;
            const userName = userDetails.username;
            const userId = userDetails._id;
            const requestData = {
                meterId: this.state.meterId,
                siteId: this.state.siteId,
                routeId: this.state.routeId,
                serailNumber,
                userFirstName,
                userLastName,
                userEmailId,
                userName,
                userId,
                reason: "Meter is added to the route",
                meterStatus: "Active"
            }
            let routeResponse = null;
            routeResponse = await axios.post(`${API_BASE_URL}/route_meter_map`, requestData);
            if(routeResponse.status === 200){
                const meterDetails = await this.getMeterDetail();
                this.setState({
                    meterDetail: meterDetails.data.result,
                    meterId:"0",
                    selectedMeter: "",
                    isLoading: false
                });
                this.refreshMeterList();
                ShowToast('Meter assigned successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
            }
        }
    }

    meterChangeHandler = (event) => {
        this.setState({
            selectedMeter: event,
            meterId: event.value,
        });
    }

    renderMeterOptions = (meterList) => {
        let meterOptions = [];
        if(meterList.length > 0) {
            meterList.forEach((meterItem) => {
                meterOptions.push({ value: meterItem._id, label: meterItem.serial_number })
            });
        }
        this.setState({
            meterOptions
        });
    }

    handleDelete = async (item) => {
        if (window.confirm("Are you sure you want to unassign this meter from route?")) {
            this.setState({isLoading: true});
            
            const SerialNumber = item.serial_number;
            const userDetails = JSON.parse(sessionStorage.getItem("userData"));
            const userFirstName = userDetails.first_name;
            const userLastName = userDetails.last_name; 
            const userEmailId = userDetails.email_id;
            const userName = userDetails.username;
            const userId = userDetails._id;

            const requestData = {
                siteId: this.state.siteId,
                routeId:this.state.routeId,
                SerialNumber,
                userFirstName,
                userLastName,
                userEmailId,
                userName,
                userId,
                reason: "Meter has been unassigned from route",
            }

            console.log(JSON.stringify(requestData));
            // let deleteResponse = await axios.delete(`${ API_BASE_URL }/route_meter_map/${item.map_id}`);
            let deleteResponse = await axios.post(`${API_BASE_URL}/route_meter_map/${item.map_id}`, requestData);

            if(deleteResponse.status === 200){
                const meterDetails = await this.getMeterDetail();
                this.setState({
                    meterDetail: meterDetails.data.result,
                    isLoading: false
                });
                this.refreshMeterList();
                ShowToast('Meter unassigned from route successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
            }
        }
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};
        if(this.state.meterId === undefined){
            isValid = false;
            error.meterId = "Please select meter";
        }
        if(this.state.meterId !== undefined && this.state.meterId === "0" && this.state.meterId){
            isValid = false;
            error.meterId = "Please select meter";
        }
        
        this.setState({
            error
        });
        return isValid;
    }

    showMeterReadingHandler = (routeItem) => {
        if(this.state.routeMeterInfo.pageName === "Route Master"){
            this.props.history.push({
                pathname: "/meter-reading",
                state: {
                    routeItem, 
                    pageName1:"Route Meter Mapping", 
                    pathName1:"route-meter-mapping",
                    pageName:"Route Master", 
                    pathName:"master-route-page",
                }
            });
        }
        else if(this.state.routeMeterInfo.pageName === "Route Transaction"){
            this.props.history.push({
                pathname: "/meter-reading",
                state: {
                    routeItem, 
                    pageName1:"Route Meter Mapping", 
                    pathName1:"route-meter-mapping",
                    pageName:"Route Transaction", 
                    pathName:"route-transaction-page",
                }
            });
        }
        else{
            this.props.history.push({
                pathname: "/meter-reading",
                state: {
                    routeItem, 
                    pageName1:"Route Meter Mapping", 
                    pathName1:"route-meter-mapping",
                    pageName:"Route Collection History", 
                    pathName:"route-history",
                }
            });
        }
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            {
                                this.state.routeMeterInfo.pageName1 === "Route Meter Mapping" ? (
                                    <>
                                    <li><a href={this.state.routeMeterInfo.pathName}>{this.state.routeMeterInfo.pageName}</a></li>
                                     <li>Route Meter Mapping</li>
                                     </>
                                ):(
                                    <>
                                        <li><a href={this.state.routeMeterInfo.pathName}>{this.state.routeMeterInfo.pageName}</a></li>
                                        <li>Route Meter Mapping</li>
                                    </>
                                )
                            }
                            
                        </ul>
                    </div>
                    <div className="reading-info-outer">
                        <div className="reading-wrapper">
                            <div className="site-wrapper">
                                <div className="row">
                                    <div className="site-info">
                                        <div className="site-content">
                                            <label>Site Name: </label>
                                            <label className="ml-3">{this.state.meterSiteData.site_name }</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="site-info">
                                        <div className="content">
                                            <label>Site Address: </label>
                                            <label className="ml-3">{ `${this.state.meterSiteData.street}, ${this.state.meterSiteData.city}, ${this.state.meterSiteData.pincode}` }</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="customer-wrapper">
                                <div className="row">
                                    <div className="cust-info">
                                        <div className="site-content">
                                            <label>Route Name: </label>
                                            <label className="ml-3">{ this.state.meterData.rowItem.route_name }</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="site-info">
                                        <div className="content">
                                            <label>Address: </label>
                                            <label className="ml-3">{ `${this.state.meterData.rowItem.street}, ${this.state.meterData.rowItem.city}, ${this.state.meterData.rowItem.pin_code}` }</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                       (this.state.meterDetail.length > 0 && this.state.isVisibleAddMeter === false) && (
                        <div className="addButton">
                            <ReactHTMLTableToExcel 
                            id="test-table-xls-button"
                            className="download-table-xls-button brandxls-button download-button xls-btn"
                            table="table-to-xls"
                            filename={ this.state.meterData.rowItem.route_name }
                            sheet="tablexls"
                            buttonText="Download as XLS"/>
                        </div>
                       )
                    }
                    
                    <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                        {
                            this.state.meterDetail.length > 0 ? (
                                <table id="table-to-xls" className="table list-table">
                                    <thead>
                                        <tr>
                                            <th className="pl1">Serial Number</th>
                                            <th>Meter Type</th>
                                            <th>Last Updated Reading</th>
                                            <th>Updated Reading date</th>
                                            <th>Unit</th>
                                            <th>Meter Status</th>
                                            <th>Alarms</th>
                                            <th>Consumer Number</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="reading-list-table">
                                    {
                                        this.state.meterDetail.map((routeItem) => {
                                            let meterStatusClass = "text-warning";
                                            if(routeItem.status){
                                                if(routeItem.status.toLowerCase() === "active"){
                                                    meterStatusClass = "text-success";
                                                }
                                                else if(routeItem.status.toLowerCase() ===  "faulty"){
                                                    meterStatusClass = "text-warning";
                                                }
                                            }
                                            

                                            return (
                                                <tr key={ routeItem._id }>
                                                    <td className="serialNo-td pl1" onClick={ () => this.showMeterReadingHandler(routeItem) } > { routeItem.serial_number } </td>
                                                    <td className="meterType-td" onClick={ () => this.showMeterReadingHandler(routeItem) }> { routeItem.meter_type } </td>
                                                    <td className="lastUpdatedReading-td" onClick={ () => this.showMeterReadingHandler(routeItem) }> { routeItem.last_updated_reading !== "" ? routeItem.last_updated_reading : "NA" } </td>
                                                    <td className="lastUpdatedDate-td" onClick={ () => this.showMeterReadingHandler(routeItem) }> { routeItem.last_update_date !==null ? defaultFormatDate(routeItem.last_update_date) : "NA" } </td>
                                                    <td className="unit-td" onClick={ () => this.showMeterReadingHandler(routeItem) }> {routeItem.unit !== null ?  (routeItem.unit === "0" || routeItem.unit === "m3" ? "m3" : routeItem.unit === "1" || routeItem.unit === "L" ? "L" : routeItem.unit === "2" || routeItem.unit === "GAL" ? "GAL" : routeItem.unit === "3" || routeItem.unit === "ft3" ? "ft3" : "NA") : "NA"} </td>
                                                    <td className= "meterStatus-td" onClick={ () => this.showMeterReadingHandler(routeItem) }> <mark className={`${meterStatusClass}`}>{ routeItem.status !== null ? routeItem.status : "none" }</mark> </td>
                                                    <td className="alarms-td" style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 100}} onClick={ () => this.showMeterReadingHandler(routeItem) } title = {routeItem.alarms != null ? validateBitStatusAlarms(routeItem.alarms) : "None"}> {routeItem.alarms != null ? validateBitStatusAlarms(routeItem.alarms) : "None"} </td>
                                                    <td className="consumerNo-td" onClick={ () => this.showMeterReadingHandler(routeItem) }> { routeItem.consumer_no != null ? (routeItem.consumer_no !== '' ? routeItem.consumer_no : "None") : "none"} </td>
                                                    <td  className={"action-td center"}> 
                                                    <div className="action-div">
                                                        {
                                                            this.state.isVisibleAddMeter === true ? (
                                                                <span onClick={ () => hasPermission("meter", "delete") ? this.handleDelete(routeItem) : "" } className={hasPermission("meter", "delete") ? "" : "not-active"}><i className= {`${TRASH_ICON}`} title="Delete Meter"></i></span>
                                                            ):(
                                                                <span className="not-active"><i className= {`${TRASH_ICON}`} title="Delete Meter"></i></span>
                                                            )
                                                        }
                                                        {/* <span onClick={ () => hasPermission("meter", "delete") ? this.handleDelete(routeItem) : "" } className={hasPermission("meter", "delete") ? "" : "not-active"}><i className= {`${TRASH_ICON}`} title="Delete Meter"></i></span> */}
                                                        <span onClick={ () => hasPermission("meter", "delete") ? this.showMeterReadingHandler(routeItem) : "" } className={hasPermission("meter", "delete") ? "" : "not-active"}><i className={`${EYE_ICON}`} title="View"></i></span>
                                                    </div>
                                                </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            ):(
                                <div className="text-center pt-2"> No Records Found!</div>
                            )
                        }
                    </div>
                    <div className="list-wrapper">
                        {
                        hasPermission("meter", "create") && this.state.isVisibleAddMeter === true && (
                            <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                <div className="row">
                                    <div className="AddMeter">
                                        <label className="form-label"> Meter  <span className="text-danger">*</span></label>
                                        <Select
                                            placeholder={'Select Meter'}
                                            value={this.state.selectedMeter}
                                            onChange={this.meterChangeHandler}
                                            options={this.state.meterOptions}
                                            isSearchable={true}
                                            isMulti={false}
                                        />
                                        <div className="text-danger error-msg">
                                            {this.state.error.meterId}
                                        </div>
                                    </div>  
                            
                                    <div className="AddButton">
                                        <button className="brand-button" onClick={this.createMeterHandler}>Add Meter</button>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div>
                </div>
            )
        }
    }
        
}

export default RouteMeterMappingPage